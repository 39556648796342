import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Start from '../components/Start/Start'
import { start, shareNatively } from '../actions/app'
import { isAppReady, hasLoadingError } from '../selectors'

const StartContainer = props => <Start {...props} />

const mapStateToProps = state => ({
  isFinishedLoading: isAppReady(state),
  hasErrors: hasLoadingError(state),
  version: state.version.name,
  versionNotFound: state.version.versionNotFound,
  isNewVersion: state.version.isNewVersion,
})

const mapDispatchToProps = dispatch => ({
  start: bindActionCreators(start, dispatch),
  shareNatively: bindActionCreators(shareNatively, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StartContainer)
