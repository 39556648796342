import React from 'react'

const translations = {
  yes: 'yes',
  no: 'no',
  start: 'enter',
  restart: 'restart',
  permission: (
    <div>
      By using this service you agree to allow Cerutti Film and Moniker to use your photos as part
      of Are You There? and accept that you will be bound to the
      {' '}
      <a
        href="https://docs.google.com/document/u/1/d/e/2PACX-1vSA4HLDzJNGe_RmjE3oc4dyZBb6yQBXdaR6Q1vUR6Plu_vTgy2wY13JIHQvTS8hzYMPD2tg4eFp58Qx/pub"
        rel="noopener noreferrer"
        target="_blank"
      >
        terms and conditions
      </a>
      {' '}
      of use in full.
    </div>
  ),
  proceed: 'proceed',
  'assignment.uploading': <>Uploading your photo&hellip;</>,
  'error.loading': 'Something went wrong while loading, please refresh the page to try again.',
  'error.camera':
    'To contribute to this experience we need access to your camera. Please press restart to ask for permission again.',
  'error.cameraAndroid': (
    <>
      <p>Please allow camera access to contribute your photo&apos;s to this video.</p>
      <p>You can allow camera access under Site Settings in your browser.</p>
    </>
  ),
  'error.notSupported': (
    <>
      <p>
        To use this website we need access to your camera. Unfortunately your phone or browser
        doesn&apos;t support this.
      </p>
      <p>Please use Safari on iOS 11 or later</p>
    </>
  ),
  'error.notSupportedAndroid': (
    <>
      <p>
        To use this website we need access to your camera. Unfortunately your phone or browser
        doesn&apos;t support this.
      </p>
      <p>Please use Chrome 53 or later</p>
    </>
  ),
  'error.inApp': (
    <>
      <p>
        You&apos;re browsing from within an app. Open this website in a standalone browser to
        participate.
      </p>
      <p>Tap the link to copy the URL.</p>
    </>
  ),
  'error.versionNotFound':
    'Unfortunately this version can not be found. Press Restart to load the front page.',
  loading: 'Loading',
  tutorial: 'Tap a shape',
  'enable.sound': 'Enable sound',
  'world.continue': props => (
    <>
      {'… or go to your version'}
      <br />
      &lsquo;
      {props.name}
      &rsquo;
    </>
  ),
  'world.build.about': 'Create a personal version',
  'world.continue.about': 'Load your personal version',
  share: 'Share',
  'share.text.default':
    'Photograph your inner wolf, together with all other visitors. Music by Joep Beving & Suzanne Ciani.',
  'share.text.version': props => `Dears, Join My Inner Wolf '${props.name}'. The magic link is ${props.link}`,
  'share.overlay': 'Tap this link to copy and share.',
  'share.copied': 'Copied link, ready to share!',
  'share.copied.short': 'Copied link!',
  'share.bar.version': (
    <>
      Tap here to share
      <br className="responsive" />
      with your friends
    </>
  ),
  mute: 'Audio is off',
  muted: 'Audio is on',
  'about.short': (
    <>
      <p>Are you there?</p>
      <p>
        With innerwolf.space you can shape the dark side of your inner world. Your &lsquo;Inner
        wolf&rsquo;.
      </p>
      <p>
        The animation on innerwolf.space is an endless collage of photos uploaded by all previous
        visitors.
      </p>
      <p className="desktop-only">
        You can also create a personal edition with just your own images. Open myinnerwolf.space on
        your mobile phone and click on the button at the bottom of the colophon… Do it, you will not
        regret it!
      </p>
      <p className="not-desktop">
        You can also create a personal edition with just your own images. Just tap the button at the
        bottom of this screen… Do it, you will not regret it!
      </p>
      <div className="embed-container">
        <iframe
          title="trailer"
          src="https://player.vimeo.com/video/367717709"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
      <p>If you’re not there where are you?</p>
      <p>
        This question is central to a research among young people with absence epilepsy in
        collaboration with artists. Together they try to find language for the experience of absence
        during an absence. Watch the research film above or read more on
        {' '}
        <a href="https://areyouthere.nl/" rel="noopener noreferrer" target="_blank">
          areyouthere.nl
        </a>
      </p>
      <p>
        By shaping your own &lsquo;Inner Wolf&rsquo; you contribute to the
        growth of a language for something very elusive but very normal:
        &lsquo;not being there for a moment&rsquo;.
      </p>
      <p>
        <a
          href="https://www.dropbox.com/sh/o1o56chnbf833ta/AABEbBESFVfTvrqSI5jZ_NFxa?dl=1"
          rel="noopener noreferrer"
          target="_blank"
        >
          Download presskit (428 MB)
        </a>
      </p>
      <p>
        This project has been generously supported by the
        {' '}
        <a href="https://www.vsbfonds.nl/" rel="noopener noreferrer" target="_blank">
          VSBfonds
        </a>
        .
      </p>
    </>
  ),
  'about.credits': (
    <>
      <p>
        Director: Moniker
        <br />
        Assistant Director:
        <br className="responsive off-tablet" />
        <a href="http://www.nevejan.nl/" rel="noopener noreferrer" target="_blank">
          Maartje Nevejan
        </a>
      </p>
      <p>
        Concept and technology
        <br className="responsive off-tablet" />
        by
        {' '}
        <a href="https://www.studiomoniker.com" rel="noopener noreferrer" target="_blank">
          Moniker
        </a>
        :
        <br />
        Luna Maurer &#38; Roel Wouters
        <br />
        Jolana Sýkorová
        <br />
        Thomas Boland
        <br />
        Grischa Erbe
      </p>
      <p>
        Commissioned by
        <br className="responsive off-tablet" />
        <a href="http://www.ceruttifilm.nl/" rel="noopener noreferrer" target="_blank">
          Cerutti Film
        </a>
      </p>
      <p>
        Music by Joep Beving & Suzanne Ciani.
        <br className="not-mobile-portrait" />
        Listen to the track on
        {' '}
        <a
          href="https://open.spotify.com/track/1ApcdqaszE4EpOp50FinUy?si=txvRgCmRREeQnwELvhHzEg"
          rel="noopener noreferrer"
          target="_blank"
        >
          Spotify
        </a>
        {' '}
        or
        {' '}
        <a
          href="https://music.apple.com/nl/album/432-suzanne-ciani-rework/1436246185?i=1436246462"
          rel="noopener noreferrer"
          target="_blank"
        >
          Apple Music
        </a>
      </p>

      <p>
        Typefaces:
        <br />
        Till by
        {' '}
        <a href="http://www.paulinelepape.com" rel="noopener noreferrer" target="_blank">
          Pauline Le Pape
        </a>
        <br />
        Vizner by Jolana Sýkorová
      </p>

      <p>
        Follow Moniker
        <br className="responsive off-tablet" />
        on
        {' '}
        <a
          href="https://www.instagram.com/studiomoniker/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Instagram
        </a>
      </p>
    </>
  ),
  'visit.mobile': 'Visit My Inner Wolf on your phone to contribute',
  explanation: (
    <>
      <p>
        My Inner Wolf is build with photo&apos;s from its users. If you want to add a photo to the
        video we need access to your camera.
      </p>
    </>
  ),
  continue: 'continue',
}

export default translations
