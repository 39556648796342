import React from 'react'
import PropTypes from 'prop-types'

import './CameraButtons.scss'
import RecordButton from '../Buttons/RecordButton'
import SwitchCameraButton from '../Buttons/SwitchCameraButton'
import CloseButton from '../Buttons/CloseButton'

const CameraButtons = ({
  takePicture, toggleFacingMode, facingMode, onClose,
}) => (
  <div className="CameraButtons">
    {/* <div className="shadow" /> */}
    <CloseButton onTap={onClose} />
    <RecordButton onTap={takePicture} />
    {facingMode && <SwitchCameraButton onTap={toggleFacingMode} />}
  </div>
)

CameraButtons.propTypes = {
  takePicture: PropTypes.func.isRequired,
  toggleFacingMode: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  facingMode: PropTypes.string,
}

CameraButtons.defaultProps = {
  facingMode: null,
}

export default CameraButtons
