import DetectRTC from 'detectrtc'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import ReactGA from 'react-ga'

import constants from './constants'
import viewer from '../viewer'
import TextureManager from '../utils/TextureManager'
import { loadVersion } from './version'
import PlaybackController from '../utils/PlaybackController'
import WebcamScene from '../animation/WebcamScene'
import { getInstruction } from '../selectors'
import { saveUser } from './user'
import history from '../utils/history'
import audio from '../audio'
import supportsWebShare from '../utils/supportsWebShare'
import slugToTitle from '../utils/slugToTitle'
import settings from '../settings'
import staticTextures from '../assets/animation/textures'
import isInApp from '../utils/detectInApp'

export const processUrl = pathname => (dispatch, getState) => {
  console.log('changed url', pathname)
  const { version, app } = getState()

  const url = pathname.replace(/^\/+/g, '')
  const allowed = ['about', 'over']
  const parts = url.split('/')

  const hasAbout = allowed.includes(parts[0]) || allowed.includes(parts[1])
  let versionName = parts[0] || 'frontpage'
  if (hasAbout && allowed.includes(parts[0])) versionName = 'frontpage'

  if (!version.textures || (versionName && version.name !== versionName)) {
    dispatch(loadVersion(versionName || undefined))

    PlaybackController.reset()
    dispatch({ type: constants.RESTART_APP })
  }

  if (hasAbout) {
    PlaybackController.pause()
    dispatch({ type: constants.SHOW_ABOUT })
    ReactGA.event({
      category: 'about',
      action: 'opened',
    })
  } else {
    if (app.isAboutVisible && !app.showStartScreen) PlaybackController.play()
    dispatch({ type: constants.HIDE_ABOUT })
  }
}

export const checkDeviceSupport = () => dispatch => {
  DetectRTC.load(() => {
    if (DetectRTC.isMobileDevice && !DetectRTC.hasWebcam) {
      dispatch({ type: constants.DEVICE_NOT_SUPPORTED })
      ReactGA.event({
        category: 'app',
        action: 'not supported',
        label: isInApp ? 'is in app' : 'no webcam',
      })
    }
  })
}

export const loadDependencies = () => async dispatch => {
  const { isPackaged } = settings

  let sceneData = {
    ...settings.animation,
  }

  if (!isPackaged) {
    const { data: texts } = await axios.get(`${process.env.REACT_APP_API_URL}/contributions/static`)
    const textures = texts.reduce((acc, cur) => {
      acc[cur.object] = `${process.env.REACT_APP_UPLOADS_URL}/images/${cur.filename}`
      return acc
    }, {})

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/scenes/current`)
    if (!data.inOutPoints) {
      alert('No inOutPoints set for this scene!')
      return
    }
    const { data: timeline } = await axios.get(
      `${process.env.REACT_APP_UPLOADS_URL}/inOutPoints/${data.inOutPoints}`,
    )
    sceneData = {
      ...sceneData,
      inOutPoints: timeline,
      filename: `${process.env.REACT_APP_UPLOADS_URL}/scenes/${data.filename}`,
      fov: {
        animation: data.fov,
        multipliers: {
          portrait: data.fovPortraitMultiplier,
          cinematic: data.fovExtremeLandscapeMultiplier,
        },
      },
      static: textures,
    }
  } else {
    const textures = Object.keys(staticTextures).reduce((acc, cur) => {
      acc[cur] = `${process.env.PUBLIC_URL}/textures/${staticTextures[cur]}`
      return acc
    }, {})

    sceneData = {
      ...sceneData,
      static: textures,
    }
  }

  dispatch({ type: constants.SCENE_DATA_LOADED, data: sceneData })
  dispatch(checkDeviceSupport())
  dispatch(processUrl(window.location.pathname))
}

export const start = () => (dispatch, getState) => {
  dispatch({ type: constants.START_APP })
  const { isMuted } = getState().app
  audio.mute(isMuted)
  PlaybackController.play()
  viewer.startBenchmark()

  ReactGA.event({
    category: 'app',
    action: 'start',
  })
}

export const hideExplanation = () => async dispatch => {
  await dispatch({ type: constants.HIDE_EXPLANATION })
  dispatch(saveUser())
}

export const selectObject = name => async (dispatch, getState) => {
  const doIt = () => dispatch({
    type: constants.SELECT_OBJECT,
    data: {
      name,
    },
  })

  const { user, app } = getState()
  if (app.selectedObject) {
    WebcamScene.prepareSwap()
  }

  // if we already have camera permission we can dispatch early
  // this way webcam will fade in as well...
  if (app.isCameraReady) doIt()

  const startTime = PlaybackController.time
  viewer.hasSelection = true
  viewer.fadeOutObjectsExcept(name)
  if (!app.selectedObject) {
    await PlaybackController.pause()
    await PlaybackController.gotoTime(startTime, false, 0.6)
  }

  // we only want to ask permission after fading out
  if (!app.isCameraReady) doIt()

  // in case the camera has not been initialised yet we show
  // an overlay explaning why we make photos
  if (!user.didSeeExplanation) {
    dispatch({ type: constants.SHOW_EXPLANATION })
  }

  ReactGA.event({
    category: 'app',
    action: 'selected object',
    label: name,
  })
}

export const unselectObject = () => async dispatch => {
  dispatch({
    type: constants.UNSELECT_OBJECT,
  })

  viewer.hasSelection = false
  viewer.fadeInObjects({ onComplete: WebcamScene.clear })
  PlaybackController.play()
}

export const handleAnimationTap = event => async (dispatch, getState) => {
  const { app } = getState()
  if (app.isConfirming) return // don't switch objects when confirming

  const obj = viewer.raycast(event)
  if (obj) console.log('clicked', obj.name)

  const object = viewer.raycast(event)
  if (object) dispatch(selectObject(object.name))
}

export const confirmAssignment = () => async dispatch => {
  dispatch({
    type: constants.CONFIRM_ASSIGNMENT,
  })
}

export const cancelAssignment = () => async dispatch => {
  dispatch({
    type: constants.CANCEL_ASSIGNMENT,
  })
}

export const submit = () => async (dispatch, getState) => {
  const startTime = Date.now()
  dispatch({ type: constants.UPLOAD_REQUEST })

  const state = getState()
  const { user, app, version } = state
  const instruction = getInstruction(state)

  const url = WebcamScene.canvasElement.toDataURL('image/jpeg', 0.75)
  const blob = dataURLtoBlob(url)

  const form = new FormData()
  form.set('user', user.id)
  form.set('object', app.selectedObject)
  if (version.id) form.set('versionId', version.id)
  if (instruction) form.set('questionId', instruction.groupId)
  form.append('image', blob)
  form.set('save', true)

  try {
    const { data: result } = await axios.post(
      `${process.env.REACT_APP_API_URL}/contributions`,
      form,
      {
        // maybe useful for later
        // onUploadProgress: console.log,
      },
    )

    // add texture to the texture manager so we can use it
    TextureManager.addUserTexture(app.selectedObject, url)

    // to make sure the progress/uploading screen does not flicker
    const duration = Date.now() - startTime
    const minDuration = 3000
    const timeout = duration < minDuration ? minDuration - duration : 0
    setTimeout(async () => {
      await dispatch({ type: constants.UPLOAD_SUCCESS, data: result })
      dispatch(unselectObject())
      dispatch(saveUser())
    }, timeout)

    ReactGA.event({
      category: 'contribution',
      action: 'finished',
    })
  } catch (error) {
    console.error('error uploading contribution', error)
    Sentry.captureException(error)
    dispatch({ type: constants.UPLOAD_ERROR })
  }
}

export const setCameraReady = () => dispatch => {
  dispatch({
    type: constants.CAMERA_READY,
  })

  Sentry.configureScope(scope => {
    scope.setExtra('hasCameraPermission', true)
  })
}

// TODO: figure out how to reprompt permission
export const onCameraError = error => dispatch => {
  if (error.name === 'NotAllowedError') {
    Sentry.configureScope(scope => {
      scope.setExtra('hasCameraPermission', false)
    })

    ReactGA.event({
      category: 'camera',
      action: 'declined access',
    })

    dispatch({ type: constants.DECLINED_CAMERA })
  }
}

export const setLanguage = lang => async dispatch => {
  await dispatch({ type: constants.SET_LANGUAGE, data: lang })
  ReactGA.event({
    category: 'language',
    action: 'changed',
    value: lang,
  })
  dispatch(saveUser())
}

export const shareNatively = () => (dispatch, getState) => {
  const { copy, version } = getState()
  const link = window.location.href.replace('/about', '').replace('/over', '')
  const translated = copy.translations[copy.lang]
  const formatted = version.name !== 'frontpage'
    ? translated['share.text.version']({
      name: slugToTitle(version.name),
      link,
    })
    : translated['share.text.default']

  return new Promise(async (resolve, reject) => {
    try {
      await navigator.share({
        title: 'My Inner Wolf',
        text: formatted,
        url: link,
      })
      ReactGA.event({
        category: 'share',
        action: 'success',
      })
      resolve()
    } catch (error) {
      // did not share
      ReactGA.event({
        category: 'share',
        action: 'cancelled',
      })
      reject(error)
    }
  })
}

// this share should only be called when animation is playing
export const share = () => async dispatch => {
  PlaybackController.pause()
  if (supportsWebShare()) {
    try {
      await dispatch(shareNatively())
      ReactGA.event({
        category: 'share',
        action: 'success',
      })
    } catch (error) {
      // did not share
      ReactGA.event({
        category: 'share',
        action: 'cancelled',
      })
    }
  } else {
    dispatch({ type: constants.SHOW_SHARE_OVERLAY, data: true })
  }
}

export const hideShare = () => dispatch => {
  dispatch({ type: constants.SHOW_SHARE_OVERLAY, data: false })
  ReactGA.event({
    category: 'share',
    action: 'hide overlay',
  })
}

export const hideAbout = () => (dispatch, getState) => {
  const { version } = getState()
  const url = version.name === 'frontpage' ? '/' : `/${version.name}`
  history.push(url)
}

export const toggleSound = () => async (dispatch, getState) => {
  await dispatch({ type: constants.TOGGLE_SOUND })
  const { app } = getState()
  audio.mute(app.isMuted)
  dispatch(saveUser())

  ReactGA.event({
    category: 'sound',
    action: 'toggled',
    value: +app.isMuted,
  })
}
