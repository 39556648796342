import React from 'react'
import SvgButton from './SvgButton'

const AboutButton = props => (
  <SvgButton id="About" {...props}>
    <svg width="25.371" height="25.371" viewBox="0 0 25.371 25.371">
      <circle cx="12.637" cy="12.637" r="12.637" fill="#fff" transform="translate(0 .097)" />
      <path d="M18.012 8.479c0 3.948-4.874 3.829-4.4 7.752h-2.473c-.546-4.589 3.948-4.8 3.948-7.514a2.2 2.2 0 0 0-2.473-2.116 2.491 2.491 0 0 0-2.615 2.615l-2.641-.428c.167-2.426 2-4.589 5.326-4.589 3.545.001 5.328 2.236 5.328 4.28zM14.09 20.012a1.677 1.677 0 1 1-1.688-1.664 1.674 1.674 0 0 1 1.687 1.665z" />
    </svg>
  </SvgButton>
)

export default AboutButton
