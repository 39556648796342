const instructions = {
  1: {
    groupId: 1,
    confirmation_en: 'Are you happy with this curtain?',
    confirmation_nl: 'Bent u tevreden over dit gordijn?',
    instruction_en: 'Take a photo of a curtain',
    instruction_nl: 'Maak een foto van een gordijn',
  },
  2: {
    groupId: 2,
    confirmation_en: 'Is this the right view?',
    confirmation_nl: 'Is het weids?',
    instruction_en: 'Take a photo of an outside view',
    instruction_nl: 'Maak een foto van een uitzicht',
  },
  3: {
    groupId: 3,
    confirmation_en: 'Does this wall fit the scene?',
    confirmation_nl: 'Past deze muur in deze scene?',
    instruction_en: 'Take a photo of a wall',
    instruction_nl: 'Maak een foto van een muur',
  },
  4: {
    groupId: 4,
    confirmation_en: 'Does this plint fit the scene?',
    confirmation_nl: 'Past deze plint bij de muur?',
    instruction_en: 'Take a photo of a plint',
    instruction_nl: 'Maak een foto van een plint',
  },
  5: {
    groupId: 5,
    confirmation_en: 'Are you happy with your selfie?',
    confirmation_nl: 'Ben je tevreden over je selfie?',
    instruction_en: 'Take a selfie',
    instruction_nl: 'Maak een selfie',
  },
  6: {
    groupId: 6,
    confirmation_en: 'Does the hair fits the head?',
    confirmation_nl: 'Past het haar bij het hoofd?',
    instruction_en: 'Take a photo of hair',
    instruction_nl: 'Maak een foto van je haar',
  },
  7: {
    groupId: 7,
    confirmation_en: 'Is this a parent?',
    confirmation_nl: 'Is dit een ouder?',
    instruction_en: 'Take a photo of a parent',
    instruction_nl: 'Maak een foto van een ouder',
  },
  8: {
    groupId: 8,
    confirmation_en: 'Are you happy with this pattern?',
    confirmation_nl: 'Ben je tevreden over dit patroon?',
    instruction_en: 'Take a photo of a colourful pattern',
    instruction_nl: 'Maak een foto van een kleurrijke patroon',
  },
  9: {
    groupId: 9,
    confirmation_en: 'Happy with this ear?',
    confirmation_nl: 'Blij met dit oor?',
    instruction_en: 'Take a photo of an ear',
    instruction_nl: 'Maak een foto van een oor',
  },
  10: {
    groupId: 10,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Take a photo of a face',
    instruction_nl: 'Maak een foto van een gezicht',
  },
  11: {
    groupId: 11,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Take a photo of an eye',
    instruction_nl: 'Maak een foto van een oog',
  },
  12: {
    groupId: 12,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Take a photo of a hand',
    instruction_nl: 'Neem een ​​foto van een hand',
  },
  13: {
    groupId: 13,
    confirmation_en: 'Is this the best you can do?',
    confirmation_nl: 'Is dit het beste wat je kan doen?',
    instruction_en: 'Take a photo of a neck',
    instruction_nl: 'Maak een foto van een nek',
  },
  14: {
    groupId: 14,
    confirmation_en: 'Is this the best you can do?',
    confirmation_nl: 'Is dit het beste wat je kan doen?',
    instruction_en: 'Take a photo of a fabric',
    instruction_nl: 'Maak een foto van een stof',
  },
  15: {
    groupId: 15,
    confirmation_en: 'Does this arm fit the body?',
    confirmation_nl: 'Past dit arm bij het lichaam?',
    instruction_en: 'Take a photo of an arm',
    instruction_nl: 'Maak een foto van een arm',
  },
  16: {
    groupId: 16,
    confirmation_en: 'Is this the shiniest thing near you?',
    confirmation_nl: 'Is dit het meest glazende in de buurt?',
    instruction_en: 'Take a photo of a shiny surface',
    instruction_nl: 'Maak een foto van een glanzend oppervlak',
  },
  17: {
    groupId: 17,
    confirmation_en: 'Is it legible?',
    confirmation_nl: 'Is het goed leesbaar?',
    instruction_en: 'Photograph a poster',
    instruction_nl: 'Fotografeer een poster',
  },
  18: {
    groupId: 18,
    confirmation_en: 'Are you happy with this cup?',
    confirmation_nl: 'Ben je tevreden met deze beker?',
    instruction_en: 'Photograph a cup',
    instruction_nl: 'Fotograferen een kopje',
  },
  19: {
    groupId: 19,
    confirmation_en: 'Does this fit the scene?',
    confirmation_nl: 'Past het in de scene?',
    instruction_en: 'Photograph a solid color',
    instruction_nl: 'Fotografeer een effen kleur',
  },
  20: {
    groupId: 20,
    confirmation_en: 'Are you happy with this table cloth?',
    confirmation_nl: 'Ben je tevreden over deze tafelkleed?',
    instruction_en: 'Photograph a tablecloth',
    instruction_nl: 'Fotografeer een tafelkleed',
  },
  21: {
    groupId: 21,
    confirmation_en: 'Would you eat this?',
    confirmation_nl: 'Wil je dit eten?',
    instruction_en: 'Take a photo of your plate',
    instruction_nl: 'Maak een foto van je bord',
  },
  22: {
    groupId: 22,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Take a photo of the floor',
    instruction_nl: 'Maak een foto van de vloer',
  },
  23: {
    groupId: 23,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Photograph a fork',
    instruction_nl: 'Fotografeer een vork',
  },
  24: {
    groupId: 24,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Photograph the ceiling',
    instruction_nl: 'Fotografeer het plafond',
  },
  25: {
    groupId: 25,
    confirmation_en: 'Is it abstract?',
    confirmation_nl: 'Is het abstract?',
    instruction_en: 'Photograph a black and white pattern',
    instruction_nl: 'Fotografeer een zwart-wit patroon',
  },
  26: {
    groupId: 26,
    confirmation_en: 'Does it has nice big type on it?',
    confirmation_nl: 'Heeft het heeft mooie grote letters?',
    instruction_en: 'Photograph a sweater',
    instruction_nl: 'Fotografeer een trui',
  },
  27: {
    groupId: 27,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Photograph geometric shapes',
    instruction_nl: 'Fotograferen geometrische vormen',
  },
  28: {
    groupId: 28,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Photograph the pattern you see when your eyes are closed',
    instruction_nl: 'Fotografeer het patroon dat je ziet als je ogen zijn gesloten',
  },
  29: {
    groupId: 29,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Photograph the tiny animals you see when your eyes are closed',
    instruction_nl: 'Fotografeer de kleine dieren die je ziet wanneer je ogen zijn gesloten',
  },
  30: {
    groupId: 30,
    confirmation_en: 'Are you scared of this photo?',
    confirmation_nl: 'Ben je bang voor deze foto?',
    instruction_en: 'Take a photo of something that scares you',
    instruction_nl: 'Maak een foto van iets engs',
  },
  31: {
    groupId: 31,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Photography the texture of your inner world',
    instruction_nl: 'Fotografie de textuur van je binnen wereld',
  },
  32: {
    groupId: 32,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Photograph a black surface',
    instruction_nl: 'Fotograferen een zwart oppervlak',
  },
  33: {
    groupId: 33,
    confirmation_en: 'Is this the deepest dark?',
    confirmation_nl: 'Is dit het diepste donker?',
    instruction_en: 'Photograph a dark space',
    instruction_nl: 'Fotografeer een donkere ruimte',
  },
  34: {
    groupId: 34,
    confirmation_en: 'Does this reflect your inner world?',
    confirmation_nl: 'Weerspiegelt dit beeld je innerlijke wereld?',
    instruction_en: 'Photograph your inner world',
    instruction_nl: 'Fotografeer je innerlijke wereld',
  },
  35: {
    groupId: 35,
    confirmation_en: 'Are these the eyes of your inner wolf?',
    confirmation_nl: 'Zijn dit de ogen van je innerlijke wolf?',
    instruction_en: 'Photograph the nose of your inner wolf',
    instruction_nl: 'Fotografeer de neus van je innerlijke wolf',
  },
  36: {
    groupId: 36,
    confirmation_en: 'Is this the nose of your inner wolf?',
    confirmation_nl: 'Is dit de neus van je innerlijke wolf?',
    instruction_en: 'Photograph the eyes of your inner wolf',
    instruction_nl: 'Fotografeer de ogen van je innerlijke wolf',
  },
  37: {
    groupId: 37,
    confirmation_en: 'Is this the mouth of your inner wolf?',
    confirmation_nl: 'Is dit de mond van je innerlijke wolf?',
    instruction_en: 'Photograph the mouth of your inner wolf',
    instruction_nl: 'Fotografeer de mond van je innerlijke wolf',
  },
  38: {
    groupId: 38,
    confirmation_en: 'Does this represents your inner wolf?',
    confirmation_nl: 'Vertegenwoordigt dit je innerlijke wolf?',
    instruction_en: 'Photograph your inner wolf',
    instruction_nl: 'Fotografeer je innerlijke wolf',
  },
  39: {
    groupId: 39,
    confirmation_en: 'Are you happy with this photo?',
    confirmation_nl: 'Ben je tevreden over deze foto?',
    instruction_en: 'Photograph a wooden surface',
    instruction_nl: 'Fotografeer een houten ondergrond',
  },
  40: {
    groupId: 40,
    instruction_en: 'Photograph an ankle',
    confirmation_en: 'Are you happy with this photo?',
    instruction_nl: 'Fotografeer een enkel',
    confirmation_nl: 'Ben je tevreden over deze foto?',
  },
  41: {
    groupId: 41,
    instruction_en: 'Photograph a shoe',
    confirmation_en: 'Are you happy with this photo?',
    instruction_nl: 'Fotografeer een schoen',
    confirmation_nl: 'Ben je tevreden over deze foto?',
  },
  42: {
    groupId: 42,
    instruction_en: 'Photograph jeans',
    confirmation_en: 'Are you happy with this photo?',
    instruction_nl: 'Fotografeer een spijkerbroek',
    confirmation_nl: 'Ben je tevreden over deze foto?',
  },
  43: {
    groupId: 43,
    instruction_en: 'Photograph a sock',
    confirmation_en: 'Are you happy with this photo?',
    instruction_nl: 'Fotografeer een sok',
    confirmation_nl: 'Ben je tevreden over deze foto?',
  },
  44: {
    groupId: 44,
    instruction_en: 'Photograph a rug',
    confirmation_en: 'Does it tie the room together?',
    instruction_nl: 'Fotografeer een tapijt',
    confirmation_nl: 'Ben je tevreden met dit tapijt?',
  },
}

export default instructions
