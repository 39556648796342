import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
import 'array-flat-polyfill'

import './index.scss'
import * as serviceWorker from './serviceWorker'
import store from './store'
import AppContainer from './containers/AppContainer'
import Page from './utils/Page'

import './audio'

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)
  ReactGA.set({ anonymizeIp: true })
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  })
}

ReactDOM.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

// try to prevent scaling on iOS as much as possible...
if (Page.isIos) {
  document.addEventListener(
    'touchmove',
    e => {
      if (e.scale !== 1) e.preventDefault()
    },
    { passive: false },
  )
}
