import React from 'react'
import { connect } from 'react-redux'

import About from '../components/About/About'
import { isUserVersion } from '../selectors'

const AboutContainer = props => <About {...props} />

const mapStateToProps = state => ({
  isUserVersion: isUserVersion(state),
})

export default connect(mapStateToProps)(AboutContainer)
