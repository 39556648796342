import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import device from 'current-device'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import device from '../../utils/current-device'

import './App.scss'

import AssignmentContainer from '../../containers/AssignmentContainer'
import StartContainer from '../../containers/StartContainer'
import Viewer from '../Viewer/Viewer'
import LocaleContext from '../locale/LocaleContext'
import AboutButton from '../Buttons/AboutButton'
// import ShareRoundButton from '../Buttons/ShareRoundButton'
import history from '../../utils/history'
import TapHint from '../Hint/TapHint'
import Error from '../Error/Error'
import CameraContainer from '../../containers/CameraContainer'
import ShareOverlay from '../ShareOverlay/ShareOverlay'
import AboutContainer from '../../containers/AboutContainer'
import AudioHint from '../Hint/AudioHint'
import ExplanationOverlay from '../ExplanationOverlay/ExplanationOverlay'
import DesktopHint from '../Hint/DesktopHint'
import isInApp from '../../utils/detectInApp'

class App extends Component {
  state = {
    presentationMode: false,
  }

  constructor(props) {
    super(props)

    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.openAbout = this.openAbout.bind(this)
  }

  componentWillMount() {
    const { loadDependencies } = this.props
    loadDependencies()

    document.addEventListener('keyup', this.handleKeyPress)
  }

  componentDidMount() {
    if (window.location.search === '?installation') {
      this.togglePresentationMode()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyPress)
  }

  handleKeyPress(e) {
    switch (e.key) {
      case 'p':
        this.togglePresentationMode()
        break
      default:
        break
    }
  }

  togglePresentationMode() {
    this.setState({
      presentationMode: !this.state.presentationMode,
    })
  }

  openAbout() {
    const { locale } = this.props.copy
    const current = window.location.pathname.replace('/', '')
    const slug = locale === 'nl' ? 'over' : 'about'
    const aboutUrl = current ? `${current}/${slug}` : slug
    history.push(aboutUrl)
  }

  render() {
    const {
      selectedObject,
      unselectObject,
      showStartScreen,
      isCameraReady,
      copy,
      isAboutVisible,
      isShareVisible,
      // share,
      hideShare,
      hideAbout,
      didTap,
      didDeclineCamera,
      isDeviceSupported,
      showExplanation,
      hideExplanation,
      isSceneDataLoaded,
      sceneData,
      isFinishedLoading,
      versionNotFound,
      isAnimatingOut,
    } = this.props
    const hasSelection = !!selectedObject

    const { presentationMode } = this.state
    const isUiVisible = isDeviceSupported
      && (!showStartScreen || isFinishedLoading)
      && !hasSelection
      && !presentationMode
      && !isShareVisible

    const className = classNames('App', presentationMode && 'installation')

    return (
      <LocaleContext.Provider value={copy}>
        <div className={className}>
          {isSceneDataLoaded && <Viewer scene={sceneData} />}
          <CameraContainer />

          {!isDeviceSupported && !isInApp && (
            <Error
              canRestart={false}
              messageId={device.android() ? 'error.notSupportedAndroid' : 'error.notSupported'}
            />
          )}

          {!isDeviceSupported && isInApp && <ShareOverlay />}

          {isDeviceSupported && (
            <div className="ui">
              <CSSTransition
                in={showStartScreen && !isAnimatingOut}
                timeout={{
                  appear: 0,
                  enter: 0,
                  exit: 2000,
                }}
                unmountOnExit
              >
                <StartContainer />
              </CSSTransition>
              {isUiVisible && !versionNotFound && (
                <div className="NavButtons">
                  <AboutButton onTap={this.openAbout} />
                </div>
              )}
              {!showStartScreen && (
                <>
                  {hasSelection && isCameraReady && (
                    <AssignmentContainer presentation={presentationMode} />
                  )}

                  {isUiVisible && (
                    <>
                      {!device.desktop() && <TapHint didTap={didTap} />}
                      <AudioHint />
                      {device.desktop() && <DesktopHint />}
                    </>
                  )}
                </>
              )}
              {isAboutVisible && <AboutContainer onClose={hideAbout} />}
              {isShareVisible && <ShareOverlay onClose={hideShare} />}
              {hasSelection && didDeclineCamera && (
                <Error
                  canRestart={!device.android()}
                  messageId={device.android() ? 'error.cameraAndroid' : 'error.camera'}
                  onClose={unselectObject}
                />
              )}
              {showExplanation && <ExplanationOverlay onContinue={hideExplanation} />}
            </div>
          )}
        </div>
      </LocaleContext.Provider>
    )
  }
}

App.propTypes = {
  isCameraReady: PropTypes.bool.isRequired,
  showStartScreen: PropTypes.bool.isRequired,
  // share: PropTypes.func.isRequired,
  hideShare: PropTypes.func.isRequired,
  hideAbout: PropTypes.func.isRequired,
  loadDependencies: PropTypes.func.isRequired,
  isAboutVisible: PropTypes.bool.isRequired,
  isShareVisible: PropTypes.bool.isRequired,
  selectedObject: PropTypes.string,
  unselectObject: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  copy: PropTypes.object.isRequired,
  didTap: PropTypes.bool.isRequired,
  didDeclineCamera: PropTypes.bool.isRequired,
  isDeviceSupported: PropTypes.bool.isRequired,
  showExplanation: PropTypes.bool.isRequired,
  hideExplanation: PropTypes.func.isRequired,
  isSceneDataLoaded: PropTypes.bool.isRequired,
  sceneData: PropTypes.shape({
    inOutPoints: PropTypes.object.isRequired,
    filename: PropTypes.string.isRequired,
    fov: PropTypes.shape({
      animations: PropTypes.object,
      multipliers: PropTypes.object,
    }),
  }),
  isFinishedLoading: PropTypes.bool.isRequired,
  versionNotFound: PropTypes.bool,
  isAnimatingOut: PropTypes.bool.isRequired,
  start: PropTypes.func.isRequired,
}

App.defaultProps = {
  selectedObject: null,
  sceneData: null,
  versionNotFound: false,
}

export default App
