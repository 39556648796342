import { combineReducers } from 'redux'

import app from './app'
import user from './user'
import version from './version'
import instructions from './instructions'
import copy from './copy'

export default combineReducers({
  app,
  user,
  version,
  instructions,
  copy,
})
