import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from './Button'

const SvgButton = ({
  children, id, className, ...rest
}) => (
  <Button {...rest} className={classNames('SvgButton', `${id}Button`, className)}>
    {children}
  </Button>
)

SvgButton.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
}

SvgButton.defaultProps = {
  className: '',
}

export default SvgButton
