import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import VersionBar from '../components/VersionBar/VersionBar'
import { createNewVersion } from '../actions/version'

const VersionBarContainer = props => <VersionBar {...props} />

const mapStateToProps = state => ({
  version: state.version.name,
  userVersion: state.user.ownsVersion,
})

const mapDispatchToProps = dispatch => ({
  create: bindActionCreators(createNewVersion, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VersionBarContainer)
