import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'

const RecordButton = ({ onTap }) => (
  <Button className="RecordButton" onTap={onTap}>
    <div className="innerCircle" />
  </Button>
)

RecordButton.propTypes = {
  onTap: PropTypes.func.isRequired,
}

export default RecordButton
