import * as THREE from 'three'

import Page from '../utils/Page'

class Renderer {
  get() {
    if (!this.renderer) throw new Error('Renderer is not yet defined')
    return this.renderer
  }

  init(element) {
    const renderer = new THREE.WebGLRenderer({
      canvas: element,
      antialias: true,
      // alpha: true
    })
    // renderer.setClearColor(0x000000, 0);
    // renderer.setPixelRatio(
    //   window.devicePixelRatio ? window.devicePixelRatio : 1,
    // )
    renderer.setPixelRatio(1)
    renderer.setSize(Page.width, Page.height)
    renderer.sortObjects = false
    this.renderer = renderer

    return renderer
  }
}

export default new Renderer()
