import findKey from 'lodash.findkey'

const wrappers = {
  messenger: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
  facebook: /\bFB[\w_]+\//,
  twitter: /\bTwitter/i,
  line: /\bLine\//i,
  wechat: /\bMicroMessenger\//i,
  puffin: /\bPuffin/i,
  miui: /\bMiuiBrowser\//i,
  instagram: /\bInstagram/i,
}

const isInApp = !!findKey(wrappers, regex => regex.test(window.navigator.userAgent))

export default isInApp
