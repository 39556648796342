import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Start.scss'
import WelcomeScreen from './WelcomeScreen'
import Error from '../Error/Error'
import Loading from '../Loading/Loading'
import history from '../../utils/history'

const Start = ({
  isFinishedLoading,
  start,
  hasErrors,
  version,
  shareNatively,
  isNewVersion,
  versionNotFound,
}) => {
  const classes = classNames(
    'Start',
    isFinishedLoading && 'loaded',
    hasErrors && 'error',
  )

  if (
    window.location.search === '?installation'
    && hasErrors
    && !versionNotFound
  ) {
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  return (
    <div className={classes}>
      {isFinishedLoading && !hasErrors && (
        <WelcomeScreen
          isNewVersion={isNewVersion}
          start={start}
          version={version}
          shareNatively={shareNatively}
        />
      )}
      {!isFinishedLoading && !hasErrors && <Loading />}
      {hasErrors && versionNotFound && (
        <Error
          messageId="error.versionNotFound"
          action={() => {
            history.replace('/')
          }}
        />
      )}
      {hasErrors && !versionNotFound && <Error messageId="error.loading" />}
    </div>
  )
}

Start.propTypes = {
  isFinishedLoading: PropTypes.bool.isRequired,
  start: PropTypes.func.isRequired,
  shareNatively: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  version: PropTypes.string,
  isNewVersion: PropTypes.bool,
  versionNotFound: PropTypes.bool,
}

Start.defaultProps = {
  version: null,
  isNewVersion: false,
  versionNotFound: false,
}

export default Start
