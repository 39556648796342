import inOutPoints from './assets/animation/inout-points.json'
import fov from './assets/animation/fov.json'

export default {
  isPackaged: true,
  // process.env.NODE_ENV === 'development'
  // || window.location.hostname === 'myinnerwolf.space'
  // || window.location.hostname === 'myinnerwolf.nl',
  isMuted: process.env.NODE_ENV === 'development',
  animation: {
    inOutPoints,
    filename: `${process.env.PUBLIC_URL}/Scenev99_001.glb`,
    fov: {
      animation: fov,
      multipliers: {
        portrait: 2.1,
        cinematic: 0.8,
      },
    },
  },
  times: {
    credits: [12800, 14900],
    tapHint: [1220 - 600],
    timeline: [13800],
    audioHint: [910 - 600, 1160 - 600],
    desktopHint: [910, 3200],
  },
}
