import Page from '../utils/Page'

class Camera {
  near = 1
  far = 75000

  get() {
    if (!this.camera) throw new Error('Camera is not yet defined')
    return this.camera
  }

  init(camera) {
    camera.far = this.far
    camera.near = this.near
    camera.aspect = Page.aspect
    camera.updateProjectionMatrix()

    this.camera = camera

    return camera
  }

  tick() {
    if (
      this.camera.near.toFixed(2) !== this.prevNear
      || this.camera.far.toFixed(2) !== this.prevFar
      || this.camera.fov.toFixed(2) !== this.prevFov
    ) {
      this.camera.updateProjectionMatrix()
    }

    this.prevNear = this.camera.near.toFixed(2)
    this.prevFar = this.camera.far.toFixed(2)
    this.prevFov = this.camera.fov.toFixed(2)
  }
}

export default new Camera()
