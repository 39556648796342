import * as THREE from 'three'
import mitt from 'mitt'

import Camera from './Camera'
import Page from '../utils/Page'

class AnimationController {
  isInitialised = false
  time = 0
  fov = {
    0: 30,
  }
  fovMultipliers = {
    portrait: 1.2,
    cinematic: 0.8,
  }

  constructor() {
    Object.assign(this, mitt())
    Page.on('resize', ({ aspect }) => {
      this.handleAspectChange(aspect)
    })

    this.createAnimations = this.createAnimations.bind(this)
    this.createTracks = this.createTracks.bind(this)
    this.setTimeScale = this.setTimeScale.bind(this)
    this.handleAspectChange = this.handleAspectChange.bind(this)
    this.onFinished = this.onFinished.bind(this)
    this.setFovMultipliers = this.setFovMultipliers.bind(this)
  }

  // needs gltf animations
  init(animations, scene) {
    if (this.isInitialised) return

    this.animations = animations
    this.mixer = new THREE.AnimationMixer(scene)
    this.createAnimations()
    this.action.timeScale = 0

    this.action.play()
    this.mixer.update(0)

    this.mixer.addEventListener('finished', this.onFinished)

    this.isInitialised = true
  }

  onFinished() {
    this.time = 0
    this.action.reset()
    this.emit('loop')
  }

  setFovAnimation(json) {
    if (json) this.fov = json
  }

  setFovMultipliers(multipliers) {
    if (multipliers) {
      this.fovMultipliers.portrait = multipliers.portrait || this.fovMultipliers.portrait
      this.fovMultipliers.cinematic = multipliers.cinematic || this.fovMultipliers.cinematic
      if (this.camera) {
        this.createAnimations()
        this.handleAspectChange(this.aspectRatio)
      }
    } else {
      console.info('No FOV multipliers')
    }
  }

  createAnimations() {
    // TODO: probably better to not create duplicates for ALL tracks, but just for the fov
    // that would mean three actions / more complexity
    // we should probably benchmark this for performance impact
    const portraitTracks = this.createTracks('portrait')
    const portraitClip = new THREE.AnimationClip('animations', -1, portraitTracks)
    const portraitAction = this.mixer.clipAction(portraitClip)
    portraitAction.enabled = Page.aspect <= 1

    const landscapeTracks = this.createTracks()
    const landscapeClip = new THREE.AnimationClip('animations', -1, landscapeTracks)
    const landscapeAction = this.mixer.clipAction(landscapeClip)
    landscapeAction.enabled = Page.aspect > 1 && Page.aspect <= 2.2

    const extremeLandscapeTracks = this.createTracks('extremeLandscape')
    const extremeLandscapeClip = new THREE.AnimationClip('animations', -1, extremeLandscapeTracks)
    const extremeLandscapeAction = this.mixer.clipAction(extremeLandscapeClip)
    extremeLandscapeAction.enabled = Page.aspect > 2.2

    this.duration = portraitClip.duration

    this.actions = {
      portrait: portraitAction,
      landscape: landscapeAction,
      extremeLandscape: extremeLandscapeAction,
    }

    // disable looping
    Object.keys(this.actions).forEach(a => {
      const action = this.actions[a]
      action.clampWhenFinished = true
      action.setLoop(THREE.LoopOnce, 0)
    })

    if (Page.aspect <= 1) this.action = portraitAction
    if (Page.aspect > 1 && Page.aspect <= 2.2) this.action = landscapeAction
    if (Page.aspect > 2.2) this.action = extremeLandscapeAction
  }

  createTracks(orientation = 'landscape') {
    const { name } = Camera.get()
    const tracks = [].concat(...this.animations.map(a => a.tracks))

    const fovTimes = Object.keys(this.fov)
      .map(parseFloat)
      .sort((a, b) => a - b)
    const fovValues = fovTimes.map(t => this.fov[t] || this.fov[`${t}.0`])
    const fovTrack = new THREE.KeyframeTrack(
      `${name}.fov`,
      fovTimes,
      fovValues.map(fov => {
        if (orientation === 'portrait') return fov * this.fovMultipliers.portrait
        if (orientation === 'extremeLandscape') return fov * this.fovMultipliers.cinematic
        return fov
      }),
      THREE.InterpolateDiscrete,
    )
    tracks.push(fovTrack)
    return tracks
  }

  handleAspectChange(aspect) {
    if (this.isInitialised) {
      const { time, timeScale } = this.action
      this.action.stop()
      this.action.enabled = false
      if (aspect <= 1) {
        console.log('portrait fov')
        this.action = this.actions.portrait
      } else if (aspect > 1 && aspect <= 2.2) {
        console.log('landscape fov')
        this.action = this.actions.landscape
      } else {
        console.log('cinematic fov')
        this.action = this.actions.extremeLandscape
      }

      this.action.play()
      this.action.time = time
      this.action.timeScale = timeScale
      this.action.enabled = true
      this.mixer.update(0)
    }
  }

  play() {
    this.action.timeScale = 1
    this.action.play()
  }

  pause() {
    this.action.timeScale = 0
  }

  update(delta) {
    this.mixer.update(delta)
    this.time = this.action.time
  }

  gotoTime(time) {
    if (!this.action) return

    const delta = time - this.time

    // store old timescale
    const { timeScale } = this.action
    this.action.timeScale = 1
    this.action.play()

    this.update(delta)

    // reset to previous timeScale
    this.action.timeScale = timeScale
  }

  setTimeScale(timeScale) {
    if (!this.action) return
    this.action.timeScale = timeScale
  }
}

export default new AnimationController()
