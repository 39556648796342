import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Hint.scss'
import PlaybackController from '../../utils/PlaybackController'
import Translateable from '../locale/Translatable'
import settings from '../../settings'

class TapHint extends Component {
  state = {
    shouldRender: false,
    start: settings.times.tapHint[0],
    end: settings.times.credits[0],
  }

  ref = React.createRef()

  constructor(props) {
    super(props)

    this.updateFrame = this.updateFrame.bind(this)

    PlaybackController.on('frame', this.updateFrame)
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  updateFrame(frame) {
    const { shouldRender, start, end } = this.state
    if (!shouldRender && frame >= start && frame <= end && !this.props.didTap) {
      if (this.mounted) this.setState({ shouldRender: true })
    } else if (shouldRender && (frame < start || frame > end || this.props.didTap)) {
      if (this.mounted) this.setState({ shouldRender: false })
    }
  }

  render() {
    const isVisible = !this.props.didTap && this.state.shouldRender
    const className = classNames('Hint TapHint', isVisible && 'visible')
    return (
      <div className={className}>
        <Translateable id="tutorial" />
      </div>
    )
  }
}

TapHint.propTypes = {
  didTap: PropTypes.bool.isRequired,
}

export default TapHint
