import React from 'react'

import SvgButton from './SvgButton'

const SwitchCameraButton = props => (
  <SvgButton id="SwitchCamera" {...props}>
    <svg width="32" height="24" viewBox="0 0 32 24">
      <path d="M28.239 3.692h-4.185L22.613.783A1.394 1.394 0 0 0 21.358 0H10.185a1.4 1.4 0 0 0-1.256.783l-1.44 2.909H3.761A3.784 3.784 0 0 0 0 7.492V20.2A3.783 3.783 0 0 0 3.761 24h24.478A3.783 3.783 0 0 0 32 20.2V7.486a3.784 3.784 0 0 0-3.761-3.794zm2.847 16.511a2.864 2.864 0 0 1-2.847 2.874H3.761a2.864 2.864 0 0 1-2.847-2.874V7.489a2.864 2.864 0 0 1 2.847-2.874h4.293l1.693-3.419a.488.488 0 0 1 .438-.273h11.173a.485.485 0 0 1 .437.273l1.694 3.419h4.75a2.864 2.864 0 0 1 2.847 2.874z" />
      <path d="M16 18.818a5.886 5.886 0 0 1-5.851-5.9h2.181L9.577 9.282l-2.753 3.636h2.228a6.99 6.99 0 0 0 6.948 7 6.888 6.888 0 0 0 3.592-1.009l-.568-.948a5.792 5.792 0 0 1-3.024.857zM15.618 5.92a6.89 6.89 0 0 0-3.592 1.009l.568.948a5.8 5.8 0 0 1 3.024-.849 5.887 5.887 0 0 1 5.851 5.895h-2.181l2.753 3.637 2.753-3.637h-2.227a6.991 6.991 0 0 0-6.949-7.003z" />
    </svg>
  </SvgButton>
)

export default SwitchCameraButton
