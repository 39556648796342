import React from 'react'
import SvgButton from './SvgButton'

const CloseButton = props => (
  <SvgButton id="Close" {...props}>
    <svg width="25.371" height="25.371" viewBox="0 0 25.371 25.371">
      <circle cx="12.637" cy="12.637" r="12.637" transform="rotate(-90.219 12.6861 12.63769)" />
      <path d="M14.301 12.662l3.874 3.9-1.616 1.617-3.877-3.9-3.874 3.9-1.617-1.617 3.874-3.9L7.191 8.81l1.617-1.617 3.874 3.852 3.877-3.852 1.616 1.593z" />
    </svg>
  </SvgButton>
)

export default CloseButton
