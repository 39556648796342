import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './WelcomeScreen.scss'
import LabelButton from '../Buttons/LabelButton'
import VersionBarContainer from '../../containers/VersionBarContainer'

class WelcomeScreen extends Component {
  state = {
    isNewVersion: false,
  }

  componentDidMount() {
    if (window.location.search === '?installation') {
      this.props.start()
    }
  }

  render() {
    const { start } = this.props
    const isNewVersion = this.state.isNewVersion || this.props.isNewVersion

    return (
      <div className="WelcomeScreen">
        <div className="Enter">
          <LabelButton label="start" onTap={start} />
        </div>
        {!isNewVersion && (
          <VersionBarContainer
            onCreate={() => {
              this.setState({ isNewVersion: true })
            }}
          />
        )}
      </div>
    )
  }
}

WelcomeScreen.propTypes = {
  start: PropTypes.func.isRequired,
  isNewVersion: PropTypes.bool,
}

WelcomeScreen.defaultProps = {
  isNewVersion: false,
}

export default WelcomeScreen
