import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AboutTop from '../components/About/AboutTop'
import { setLanguage, toggleSound, share } from '../actions/app'

const AboutTopContainer = props => <AboutTop {...props} />

const mapStateToProps = state => ({
  language: state.copy.lang,
  languages: state.copy.languages,
  isMuted: state.app.isMuted,
})

const mapDispatchToProps = dispatch => ({
  setLanguage: bindActionCreators(setLanguage, dispatch),
  toggleSound: bindActionCreators(toggleSound, dispatch),
  share: bindActionCreators(share, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutTopContainer)
