import constants from '../actions/constants'
import getBrowserLanguage from '../utils/getBrowserLanguage'

const initialState = {
  lang: getBrowserLanguage(),
  didAgree: false,
  didTap: false,
  didDeclineCamera: false,
  didSeeExplanation: false,
  contributions: 0,
  textures: {},
}

export default (state = initialState, action) => {
  if (state.hydrated === true) {
    const newState = {
      ...initialState,
      ...state,
      hydrated: false,
    }

    return newState
  }

  switch (action.type) {
    case constants.SET_LANGUAGE:
      return {
        ...state,
        lang: action.data,
      }

    case constants.SET_FACING_MODE:
      return {
        ...state,
        facingMode: action.data,
      }

    case constants.USER_AGREED:
      return {
        ...state,
        didAgree: true,
      }

    case constants.UPLOAD_SUCCESS:
      return {
        ...state,
        contributions: state.contributions + 1,
        textures: {
          ...state.textures,
          [action.data.object]: action.data.filename,
        },
      }

    case constants.SELECT_OBJECT:
      return {
        ...state,
        didTap: true,
      }

    case constants.DECLINED_CAMERA:
      return {
        ...state,
        didDeclineCamera: true,
      }

    case constants.VERSION_SUCCESS:
      return {
        ...state,
        // store the name of the version owned by the user
        // a user can only own 1 version
        ownsVersion: action.data.user === state.id ? action.data.name : state.ownsVersion,
      }

    case constants.HIDE_EXPLANATION:
      return {
        ...state,
        didSeeExplanation: true,
      }

    default:
      return state
  }
}
