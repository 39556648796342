import React, { Component } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import ReactGA from 'react-ga'

import './ShareOverlay.scss'
import Overlay from '../Overlay/Overlay'
import Translateable from '../locale/Translatable'
import Button from '../Buttons/Button'
import isInApp from '../../utils/detectInApp'

class ShareOverlay extends Component {
  state = {
    copied: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const { copied } = this.state
    if (copied && copied !== prevState.copied) {
      this.timeout = setTimeout(() => {
        this.setState({
          copied: false,
        })
      }, 5000)
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  render() {
    const { onClose } = this.props
    const link = window.location.href.replace('/about', '').replace('/over', '')
    return (
      <Overlay className="Share" close={!!onClose} onClose={onClose}>
        {isInApp ? <Translateable id="error.inApp" /> : <Translateable id="share.overlay" />}

        {this.state.copied ? (
          <span className="Button LabelButton">
            <Translateable id="share.copied.short" />
          </span>
        ) : (
          <Button
            className="Button LabelButton"
            onTap={() => {
              copy(link)
              this.setState({ copied: true })
              ReactGA.event({
                category: 'share',
                action: 'copied link',
              })
            }}
          >
            {link.replace('https://', '').replace(/\/$/, '')}
          </Button>
        )}
      </Overlay>
    )
  }
}

ShareOverlay.propTypes = {
  onClose: PropTypes.func,
}

ShareOverlay.defaultProps = {
  onClose: null,
}

export default ShareOverlay
