const mapping = {
  Ceiling0: 24,
  Coonector0: 12,
  Flaeche0: 19,
  Flaeche00: 19,
  Flaeche010: 19,
  Floor0: 22,
  Fork2: 19,
  Fork3: 19,
  Fork11: 23,
  Glass_bottom2: 18,
  Glass_bottom4: 18,
  Glass_bottom6: 18,
  Glass_top2: 12,
  Glass_top6: 12,
  Knife1: 12,
  Lehne0: 19,
  Lehne00: 19,
  Lehne010: 19,
  Loft: 30,
  Loft0: 34,
  Loft00: 1,
  Loft1: 33,
  Loft2: 34,
  Loft3: 33,
  Loft7: 25,
  Loft8: 34,
  Loft10: 33,
  Loft11: 29,
  Loft13: 38,
  Loft14: 27,
  Loft15: 25,
  Loft16: 27,
  Loft17: 25,
  Loft18: 27,
  Loft19: 25,
  Loft20: 27,
  Loft37: 36,
  Loft38: 37,
  Loft41: 33,
  Loft43: 14,
  Loft44: 38,
  Loft45: 35,
  Loft46: 36,
  Loft47: 38,
  Loft48: 36,
  Loft49: 37,
  Loft50: 25,
  Loft51: 27,
  Loft52: 25,
  Loft53: 27,
  Loft54: 25,
  Loft55: 27,
  Loft56: 25,
  Loft57: 27,
  Loft58: 25,
  Loft59: 27,
  Loft60: 25,
  Loft61: 31,
  Loft62: 27,
  Loft63: 25,
  Loft64: 27,
  Loft65: 20,
  Loft66: 39,
  Loft67: 39,
  Loft0101: 16,
  Loft551: 14,
  Loft552: 9,
  Loft553: 6,
  Loft554: 6,
  Loft555: 6,
  Loft556: 13,
  Loft557: 26,
  Loft700: 2,
  Loft800: 3,
  Loft902: 7,
  Loft01230: 16,
  Loft1610: 34,
  Loft1611: 33,
  Loft1612: 28,
  Loft1613: 33,
  Loft1614: 28,
  Loft1615: 33,
  Loft1616: 28,
  Loft4010: 1,
  Loft5001: 1,
  Loft5011: 10,
  Loft7011: 7,
  Loft8011: 13,
  Loft10011: 6,
  Loft10100: 16,
  Loft10101: 1,
  Loft12341: 11,
  Loft012345: 12,
  Loft16111: 34,
  Loft20100: 16,
  Loft20101: 1,
  Loft30100: 16,
  Loft30101: 1,
  Loft40120: 8,
  Loft50120: 7,
  Loft60012: 4,
  Loft60120: 7,
  Loft70120: 6,
  Loft1012301: 16,
  Loft1012341: 12,
  Loft1012345: 12,
  Loft1234568: 26,
  Loft2012345: 16,
  Loft3012345: 16,
  Loft20123456: 16,
  Loft30123456: 16,
  Loft101234568: 26,
  Loft2012345670: 16,
  Loft3012345670: 16,
  Loft2012345678911: 12,
  Loft3012345678911: 11,
  Loft201234567891011: 8,
  Loft301234567891011: 8,
  Loft2012345678910111214: 26,
  Painting: 17,
  Plate2: 21,
  Plate3: 21,
  Plate11: 21,
  Shade0: 16,
  Stand0: 12,
  Umrandung_hinten0: 4,
  Umrandung_link0: 4,
  Wand_hinten0: 3,
  Wand_links0: 3,
  switching_heads14: 5,
  switching_heads19: 5,
  Rug: 44,
  mom_ankle_left: 40,
  mom_shoe_right: 41,
  mom_shoe_left: 41,
  mom_ankle_right: 40,
  dad_ankle_left: 40,
  dad_ankle_right: 40,
  dad_shoe_left: 41,
  dad_shoe_right: 41,
  teenie_hand_right: 12,
  teenie_hand_left: 12,
  teenie_jeans: 42,
  teenie_sock: 43,
  teenie_shoe_left: 41,
  teenie_shoe_right: 41,
  Ceiling_Lamp: 16,
}

export default mapping
