import * as Sentry from '@sentry/browser'
import axios from 'axios'
import ReactGA from 'react-ga'

import constants from './constants'
import TextureManager from '../utils/TextureManager'
import PlaybackController from '../utils/PlaybackController'
import { saveUser } from './user'
import history from '../utils/history'

const { REACT_APP_API_URL, REACT_APP_UPLOADS_URL } = process.env

export const versionLoaded = ({
  id, user, textures, name,
}) => async (dispatch, getState) => {
  await dispatch({
    type: constants.VERSION_SUCCESS,
    data: {
      id,
      textures,
      name,
      user,
    },
  })

  const { app, user: userState } = getState()

  PlaybackController.pause({ fade: 0 })
  if (!TextureManager.animating) {
    await TextureManager.animateOut(0)
    TextureManager.reset()
  }

  // send them to the texture manager
  TextureManager.setTextures(textures)
  // only load static textures on frontpage
  if (!id) {
    const userTextures = Object.keys(userState.textures).reduce((acc, cur) => {
      acc[cur] = `${REACT_APP_UPLOADS_URL}/images/${userState.textures[cur]}`
      return acc
    }, {})
    TextureManager.setStaticTextures({ ...app.sceneData.static, ...userTextures })
  }

  TextureManager.setInOutPoints(app.sceneData.inOutPoints)
  TextureManager.update(PlaybackController.frame, true, true)

  dispatch(saveUser())
}

export const reloadTextures = () => async (dispatch, getState) => {
  const { name } = getState().version
  const { data } = await axios.get(`${REACT_APP_API_URL}/versions/${name}`)
  const { mapping } = data

  // prepend textures url to filename
  const textures = Object.keys(mapping).reduce((acc, cur) => {
    acc[cur] = mapping[cur].map(u => `${REACT_APP_UPLOADS_URL}/images/${u}`)
    return acc
  }, {})

  TextureManager.setTextures(textures)
}

export const loadVersion = (name = 'frontpage') => async dispatch => {
  dispatch({ type: constants.VERSION_REQUEST })
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}/versions/${name}`)
    const { mapping } = data

    // prepend textures url to filename
    const textures = Object.keys(mapping).reduce((acc, cur) => {
      acc[cur] = mapping[cur].map(u => `${REACT_APP_UPLOADS_URL}/images/${u}`)
      return acc
    }, {})

    return dispatch(
      versionLoaded({
        id: data.id,
        textures,
        name,
        user: data.user,
      }),
    )
  } catch (error) {
    if (error.response && error.response.status === 404) {
      dispatch({ type: constants.VERSION_NOT_FOUND })
    } else {
      console.error('error loading version', error)
      Sentry.captureException(error)
      dispatch({ type: constants.VERSION_ERROR, error })
    }
  }
}

// Textures part
export const texturesQueued = () => dispatch => {
  dispatch({ type: constants.TEXTURES_QUEUED })
}

export const texturesLoaded = totalLoaded => dispatch => {
  dispatch({ type: constants.TEXTURES_LOADED, data: { totalLoaded } })
}

export const texturesDestroyed = num => dispatch => {
  dispatch({ type: constants.TEXTURES_DESTROYED, data: { num } })
}

export const createNewVersion = () => async (dispatch, getState) => {
  const animation = TextureManager.animateOut()
  // make sure we always "finish" the animation
  setTimeout(() => {
    dispatch({ type: constants.ANIMATED_NEW_VERSION })
  }, 5000)
  animation.then(() => {
    dispatch({ type: constants.ANIMATED_NEW_VERSION })
  })

  const {
    user: { id },
  } = getState()

  PlaybackController.reset()
  dispatch({ type: constants.RESTART_APP })
  dispatch({ type: constants.CREATE_NEW_VERSION })

  try {
    const { data } = await axios.post(`${REACT_APP_API_URL}/versions`, { user: id })
    dispatch(versionLoaded({ ...data, textures: {} }))
    ReactGA.event({
      category: 'version',
      action: 'new',
    })
    history.push(`/${data.name}`)
  } catch (error) {
    console.error('error creating new version', error)
    Sentry.captureException(error)
  }
}
