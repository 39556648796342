import { createBrowserHistory } from 'history'
import store from '../store'
import { processUrl } from '../actions/app'

const history = createBrowserHistory()

history.listen((location, action) => {
  store.dispatch(processUrl(location.pathname))
})

export default history
