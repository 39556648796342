import React from 'react'
import PropTypes from 'prop-types'

import Translatable from '../locale/Translatable'
import Button from './Button'

const LabelButton = ({ label, onTap }) => (
  <Button className="LabelButton" onTap={onTap}>
    <Translatable id={label} />
  </Button>
)

LabelButton.propTypes = {
  label: PropTypes.string.isRequired,
  onTap: PropTypes.func.isRequired,
}

export default LabelButton
