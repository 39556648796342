import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Overlay.scss'
import CloseButton from '../Buttons/CloseButton'

class Overlay extends Component {
  componentDidMount() {
    document.body.classList.add('no-scroll')
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll')
  }

  render() {
    const {
      close, children, onClose, className,
    } = this.props
    return (
      <div className={classNames('Overlay', className)}>
        {close && <CloseButton onTap={onClose} />}
        <div className="OverlayContent">{children}</div>
      </div>
    )
  }
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
}

Overlay.defaultProps = {
  close: false,
  onClose: () => {},
  className: '',
}

export default Overlay
