import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setCameraReady, onCameraError } from '../actions/app'
import { setFacingMode } from '../actions/user'
import Camera from '../components/Camera/Camera'
import { isCameraEnabled } from '../selectors'

const CameraContainer = props => props.didSeeExplanation
  && !props.didDeclineCamera
  && !props.showStartScreen && <Camera {...props} />

const mapStateToProps = state => ({
  facingMode: state.user.facingMode,
  selectedObject: state.app.selectedObject,
  showStartScreen: state.app.showStartScreen,
  isCameraReady: state.app.isCameraReady,
  didDeclineCamera: state.user.didDeclineCamera,
  isEnabled: isCameraEnabled(state),
  didSeeExplanation: state.user.didSeeExplanation,
})

const mapDispatchToProps = dispatch => ({
  setFacingMode: bindActionCreators(setFacingMode, dispatch),
  onCameraInitialised: bindActionCreators(setCameraReady, dispatch),
  onCameraError: bindActionCreators(onCameraError, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CameraContainer)
