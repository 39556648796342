import * as THREE from 'three'
import mitt from 'mitt'
import { TweenLite } from 'gsap/TweenLite'
import { Howler } from 'howler'

import Page from './Page'
import audio from '../audio'
import AnimationController from '../animation/AnimationController'
import settings from '../settings'
import TextureManager from './TextureManager'
import roundToFps from './roundToFps'

class PlaybackController {
  frame = 0
  numFrames = 0
  time = 0
  progress = 0
  duration = 0
  fps = 60
  rate = 1
  isInitialised = false
  isPlaying = false
  clock = new THREE.Clock()

  constructor() {
    Object.assign(this, mitt())

    this.updateRate = this.updateRate.bind(this)
    // this.updateTime = this.updateTime.bind(this)
    this.setTime = this.setTime.bind(this)
    this.restart = this.restart.bind(this)
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)

    audio.on('load', () => {
      TextureManager.setDuration(Math.round(audio.duration * 60))
      // 2700 is the frame where we the least amount of objects are visible
      TextureManager.setNextRoundFrame(2700)
    })

    Page.on('visibilitychange', this.handleVisibilityChange)
  }

  handleVisibilityChange(isVisible) {
    if (!isVisible && this.isPlaying) {
      this.pause({ fade: 0 })
      this.isPlaying = true
    }
    // last state has to be playing to resume...
    if (isVisible && this.isPlaying) this.play({ fade: 1.2 })
  }

  calculateDuration() {
    this.duration = audio.duration
    this.numFrames = Math.ceil(this.duration * this.fps)
  }

  updateRate() {
    audio.setRate(this.rate)
  }

  play({ fade = 0.6 } = {}) {
    return new Promise(resolve => {
      if (!AnimationController.isInitialised) return resolve()

      if (!this.clock.running) this.clock.start()

      if (Howler.ctx.state === 'suspended') {
        Howler.ctx.resume()
      }
      audio.gotoTime(AnimationController.time)
      audio.play()
      AnimationController.gotoTime(audio.time)
      AnimationController.play()
      this.isPlaying = true

      if (this.tween && this.tween.isActive()) this.tween.kill()

      if (fade > 0) {
        this.tween = TweenLite.to(this, fade, {
          rate: 1,
          volume: settings.isMuted ? 0 : 1,
          onUpdate: this.updateRate,
          onComplete: resolve,
        })
      } else {
        this.rate = 1
        this.updateRate()
        this.volume = settings.isMuted ? 0 : 1
        resolve()
      }
    })
  }

  pause({ fade = 0.6 } = {}) {
    return new Promise(resolve => {
      if (!AnimationController.isInitialised) return resolve()

      this.emit('pause')
      this.isPlaying = false

      const onComplete = () => {
        audio.pause()
        AnimationController.pause()
        this.rate = 0.01
        this.updateRate()
        if (this.clock.running) this.clock.stop()
        audio.gotoTime(AnimationController.time)
        resolve()
      }

      if (this.tween && this.tween.isActive()) this.tween.kill()

      if (fade > 0) {
        this.tween = TweenLite.to(this, fade, {
          rate: 0.01,
          volume: 0,
          onUpdate: this.updateRate,
          onComplete,
        })
      } else {
        onComplete()
      }
    })
  }

  gotoFrame(frame, force = false) {
    const newTime = frame * (1 / this.fps)
    this.gotoTime(newTime, force)
  }

  gotoTime(time, force = false, transition = 0) {
    return new Promise(resolve => {
      const onComplete = () => {
        audio.gotoTime(time)
        AnimationController.gotoTime(time)
        this.setTime(time)
        this.emit('seek', time)
        resolve()
      }

      if (this.tween && this.tween.isActive()) this.tween.kill()

      if (time >= 0 && (time <= this.duration || force)) {
        if (transition === 0) {
          onComplete()
        } else {
          this.tween = TweenLite.to(this, transition, {
            time,
            onUpdate: () => {
              audio.gotoTime(this.time)
              AnimationController.gotoTime(this.time)
            },
            onComplete,
          })
        }
      } else {
        resolve()
      }
    })
  }

  setTime(newTime) {
    const prevFrame = this.frame
    this.time = newTime
    this.progress = this.time / this.duration
    const { time, duration } = AnimationController
    this.frame = time && duration ? Math.round((time % duration) * this.fps) : 0
    if (this.frame !== prevFrame) this.emit('frame', this.frame)
  }

  tick() {
    if (this.duration === 0) this.calculateDuration()
    audio.tick()

    // use audio time as delta unless audio is disabled
    const { time } = audio
    // round new time to an exact frame number
    // necessary for the camera cuts in the animation
    const rounded = roundToFps(time, this.fps)
    AnimationController.gotoTime(rounded)

    this.setTime(rounded)
  }

  restart() {
    audio.restart()
    this.rate = 1
    this.gotoTime(0)
    this.updateRate()
  }

  reset() {
    audio.reset()
    this.isPlaying = false
    this.rate = 1
    this.gotoTime(0)
    this.updateRate()
  }
}

export default new PlaybackController()
