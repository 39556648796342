import React, { Component } from 'react'
import classNames from 'classnames'

import './Hint.scss'
import PlaybackController from '../../utils/PlaybackController'
import Translateable from '../locale/Translatable'
import settings from '../../settings'

class AudioHint extends Component {
  state = {
    shouldRender: false,
    start: settings.times.audioHint[0],
    end: settings.times.audioHint[1],
    isShown: false,
  }

  ref = React.createRef()

  constructor(props) {
    super(props)

    this.updateFrame = this.updateFrame.bind(this)

    PlaybackController.on('frame', this.updateFrame)
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  updateFrame(frame) {
    const {
      shouldRender, start, end, isShown,
    } = this.state
    if (!shouldRender && frame >= start && frame <= end) {
      if (this.mounted) this.setState({ shouldRender: true })
    } else if (shouldRender && (frame < start || frame > end)) {
      if (this.mounted) this.setState({ shouldRender: false })
    }

    if (shouldRender && frame > end && !isShown) {
      if (this.mounted) this.setState({ isShown: true })
    }
  }

  render() {
    const { shouldRender, isShown } = this.state
    const className = classNames('Hint AudioHint', shouldRender && !isShown && 'visible')
    return (
      <div className={className}>
        <Translateable id="enable.sound" />
      </div>
    )
  }
}

export default AudioHint
