import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import device from 'current-device'
import device from '../../utils/current-device'

import slugToTitle from '../../utils/slugToTitle'
import history from '../../utils/history'
import BottomBar from '../BottomBar/BottomBar'

class VersionBar extends Component {
  render() {
    const {
      version, userVersion, create, background, onCreate,
    } = this.props

    const userVersionTitle = version && version.split('-').length >= 4
      ? version
        .split('-')
        .slice(0, 3)
        .join(' ')
        .concat('…')
      : slugToTitle(userVersion)

    const hasUserVersion = !!userVersion
    const isUserVersion = version === userVersion
    let bottomCopy = 'world.build'
    if (hasUserVersion && !isUserVersion) {
      bottomCopy = 'world.continue'
    }

    if (background) bottomCopy = `${bottomCopy}.about`

    return (
      !device.desktop()
      && bottomCopy !== 'world.build' // don't show build option on start page
      && !isUserVersion && (
        <BottomBar
          background={background}
          textId={bottomCopy}
          variables={{ name: userVersionTitle }}
          action={() => {
            if (bottomCopy.includes('world.build')) {
              if (onCreate) onCreate()
              create()
            } else {
              history.push(userVersion)
            }
          }}
        />
      )
    )
  }
}

VersionBar.propTypes = {
  create: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  version: PropTypes.string,
  userVersion: PropTypes.string,
  background: PropTypes.bool,
}

VersionBar.defaultProps = {
  version: '',
  userVersion: null,
  onCreate: undefined,
  background: false,
}

export default VersionBar
