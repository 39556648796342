import ReactGA from 'react-ga'

import constants from './constants'

export const saveUser = () => (dispatch, getState) => {
  const { user, app, copy } = getState()
  const { hydrated: hu, didDeclineCamera, ...wantedUserProps } = user

  const state = {
    user: wantedUserProps,
    app: { isMuted: app.isMuted },
    copy: { lang: copy.lang },
  }

  try {
    window.localStorage.setItem('state', JSON.stringify(state))
  } catch (error) {
    // <=iOS10 doesn't support localStorage in private mode
    console.error(error)
  }
}

export const setFacingMode = mode => async dispatch => {
  await dispatch({ type: constants.SET_FACING_MODE, data: mode })
  dispatch(saveUser())
}

export const toggleFacingMode = () => (dispatch, getState) => {
  const {
    user: { facingMode },
  } = getState()
  const newMode = facingMode === 'user' ? 'environment' : 'user'
  dispatch(setFacingMode(newMode))
}

export const didAgree = () => async dispatch => {
  await dispatch({ type: constants.USER_AGREED })
  dispatch(saveUser())
  ReactGA.event({
    category: 'user',
    action: 'agreed terms',
  })
}
