import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'webrtc-adapter'

import './Camera.scss'
import WebcamScene from '../../animation/WebcamScene'
import detectCameraPermission from '../../utils/detectCameraPermission'
import Page from '../../utils/Page'

class Camera extends Component {
  state = {
    lastDraw: performance.now(),
    isInitialising: false,
  }

  cameraRef = React.createRef()
  canvasRef = React.createRef()

  constructor(props) {
    super(props)

    this.initCamera = this.initCamera.bind(this)
    this.drawVideoToCanvas = this.drawVideoToCanvas.bind(this)
    this.handleMediaStream = this.handleMediaStream.bind(this)
    this.handleOrientationChange = this.handleOrientationChange.bind(this)

    Page.on('visibilitychange', isVisible => {
      if (!isVisible && this.stream) {
        this.stream.getTracks().forEach(track => track.stop())
        this.stream = undefined
      } else if (this.props.isEnabled) {
        this.initCamera(this.props.facingMode)
      }
    })
  }

  async componentDidMount() {
    const hasPermission = await detectCameraPermission()
    const { facingMode, isCameraReady, isEnabled } = this.props
    if (hasPermission || (isEnabled && !isCameraReady) || isCameraReady) {
      this.initCamera(facingMode)
    }

    window.addEventListener('orientationchange', this.handleOrientationChange)
    this.mounted = true
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { facingMode } = this.props
    const { hasPermission, isInitialising } = nextState
    const { isEnabled } = nextProps

    // if we don't have permission yet, but we need the camera...
    if (!hasPermission && !isInitialising && isEnabled) {
      this.initCamera(nextProps.facingMode) // initialise
    } else if (!this.stream && !isInitialising && isEnabled) {
      this.initCamera(nextProps.facingMode) // initialise
    } else if (hasPermission && this.props.isEnabled !== isEnabled && isEnabled) {
      // don't draw to canvas when camera is not enabled (expensive)
      this.drawVideoToCanvas()
    } else if (this.rAF !== undefined && this.props.isEnabled !== isEnabled) {
      cancelAnimationFrame(this.rAF)
    }

    // re-initialise the camera when changing facing mode
    if (hasPermission && facingMode !== nextProps.facingMode) {
      this.initCamera(nextProps.facingMode)
    }

    return false
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.rAF) cancelAnimationFrame(this.rAF)
    window.removeEventListener('orientationchange', this.handleOrientationChange)
  }

  handleOrientationChange() {
    if (this.state.hasPermission) {
      // orientation change will stop/change the stream
      // so we need to re-initialise the camera again
      const { facingMode } = this.props
      this.initCamera(facingMode)
    }
  }

  initCamera(newFacingMode = 'environment') {
    if (!navigator.getUserMedia) throw new Error('No navigator.getUserMedia')
    this.setState({ isInitialising: true })

    // in case we change facingMode we need to stop drawing to canvas
    if (this.rAF) cancelAnimationFrame(this.rAF)

    const constraints = {
      audio: false,
      video: newFacingMode ? { facingMode: newFacingMode } : true,
    }

    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop())
    }

    navigator.getUserMedia(constraints, this.handleMediaStream, err => {
      console.error('camera error', err.name, err.message)
      this.props.onCameraError(err)
      if (this.mounted) this.setState({ isInitialising: false })
    })
  }

  handleMediaStream(stream) {
    this.stream = stream
    const track = stream.getVideoTracks()[0]
    const settings = track.getSettings()

    this.setState({
      streamSettings: settings,
    })

    const { setFacingMode, isEnabled, onCameraInitialised } = this.props
    if (settings.facingMode) {
      setFacingMode(settings.facingMode)
    }

    this.cameraRef.current.oncanplay = () => {
      this.cameraRef.current.play()
      this.cameraRef.current.muted = true

      this.initTexture()

      this.setState({
        hasPermission: true,
        isInitialising: false,
      })

      if (isEnabled) this.drawVideoToCanvas()
      onCameraInitialised()
    }

    try {
      this.cameraRef.current.srcObject = stream
    } catch (error) {
      this.cameraRef.current.src = URL.createObjectURL(stream)
    }
  }

  drawVideoToCanvas() {
    const { lastDraw, streamSettings, cropSettings } = this.state
    const now = performance.now()
    const delta = now - lastDraw

    // don't draw more often than framerate of stream
    if (delta > 1000 / streamSettings.frameRate) {
      const video = this.cameraRef.current
      const canvas = this.canvasRef.current
      const ctx = canvas.getContext('2d')
      const {
        xOffset, yOffset, width, height,
      } = cropSettings

      if (this.props.facingMode === 'environment') {
        ctx.drawImage(video, xOffset, yOffset, width, height, 0, 0, canvas.width, canvas.height)
      } else {
        ctx.scale(-1, 1)
        ctx.translate(-canvas.width, 0)
        ctx.drawImage(video, xOffset, yOffset, width, height, 0, 0, canvas.width, canvas.height)
        ctx.setTransform(1, 0, 0, 1, 0, 0)
      }

      this.setState({
        lastDraw: performance.now(),
      })
    }

    this.rAF = requestAnimationFrame(this.drawVideoToCanvas)
  }

  initTexture() {
    const video = this.cameraRef.current
    const canvas = this.canvasRef.current

    canvas.width = 256
    canvas.height = 256

    const { videoWidth, videoHeight } = video

    let cropSettings
    if (videoWidth > videoHeight) {
      const xOffset = (videoWidth - videoHeight) / 2
      cropSettings = {
        xOffset,
        yOffset: 0,
        height: videoHeight,
        width: videoWidth - xOffset * 2,
      }
    } else {
      const yOffset = (videoHeight - videoWidth) / 2
      cropSettings = {
        yOffset,
        xOffset: 0,
        height: videoHeight - yOffset * 2,
        width: videoWidth,
      }
    }

    this.setState({
      cropSettings,
    })
    WebcamScene.setVideoTexture(this.canvasRef.current)
  }

  render() {
    return (
      <div className="Camera">
        <video ref={this.cameraRef} playsInline />
        <canvas ref={this.canvasRef} />
      </div>
    )
  }
}

Camera.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  isCameraReady: PropTypes.bool.isRequired,
  setFacingMode: PropTypes.func.isRequired,
  onCameraInitialised: PropTypes.func.isRequired,
  onCameraError: PropTypes.func,
  facingMode: PropTypes.oneOf(['user', 'environment']),
}

Camera.defaultProps = {
  facingMode: 'environment',
  onCameraError: () => {},
}

export default Camera
