import React, { Component } from 'react'

import './Loading.scss'
import Translateable from '../locale/Translatable'

class Loading extends Component {
  state = {
    current: 0,
    colors: ['#54C7F7', '#EC6CFA', '#E09A63', '#1BFBB6'],
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { current, colors } = this.state
      const newColorIdx = current + 1 >= colors.length ? 0 : current + 1
      this.setState({
        current: newColorIdx,
      })
    }, 1000)
  }

  componentWillUnmount() {
    if (this.interval) clearTimeout(this.interval)
  }

  render() {
    const { colors, current } = this.state

    return (
      <div className="Loading" style={{ background: colors[current] }}>
        <span className="text">
          <Translateable id="loading" />
        </span>
      </div>
    )
  }
}

export default Loading
