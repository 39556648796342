import * as Sentry from '@sentry/browser'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import uuid from 'uuid/v4'

import rootReducer from './reducers'
import settings from './settings'

const enhancers = []
const middleware = [thunk]

if (process.env.NODE_ENV === 'development') {
  const { __REDUX_DEVTOOLS_EXTENSION__: devToolsExtension } = window

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
)

const initialState = { user: { id: uuid() }, app: { isMuted: settings.isMuted } }
let state

try {
  state = JSON.parse(window.localStorage.getItem('state')) || initialState
} catch (error) {
  // iOS can sometimes throw an error in private mode
  state = initialState
}

Sentry.configureScope(scope => {
  scope.setUser({ id: state.user.id })
})

const hydrated = Object.keys(state).reduce((acc, cur) => {
  acc[cur] = {
    ...state[cur],
    hydrated: true,
  }
  return acc
}, {})

if (settings.isMuted && hydrated.app) hydrated.app.isMuted = settings.isMuted

export default createStore(rootReducer, hydrated, composedEnhancers)
