import React from 'react'
import PropTypes from 'prop-types'

import Overlay from '../Overlay/Overlay'
import Translateable from '../locale/Translatable'
import LabelButton from '../Buttons/LabelButton'

const PermissionPrompt = ({ onPermission, onCancel }) => (
  <Overlay close onClose={onCancel}>
    <Translateable id="permission" />
    <LabelButton label="proceed" onTap={onPermission} />
  </Overlay>
)

PermissionPrompt.propTypes = {
  onPermission: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default PermissionPrompt
