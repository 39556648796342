import constants from '../actions/constants'
import translations from '../assets/translations/index'
import getBrowserLanguage from '../utils/getBrowserLanguage'

const initialState = {
  lang: getBrowserLanguage(),
  languages: ['en', 'nl'],
  translations,
}

export default (state = initialState, action) => {
  if (state.hydrated === true) {
    const newState = {
      ...initialState,
      ...state,
      hydrated: false,
    }

    return newState
  }

  switch (action.type) {
    case constants.SET_LANGUAGE:
      return {
        ...state,
        lang: action.data,
      }

    default:
      return state
  }
}
