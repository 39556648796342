import React from 'react'

const translations = {
  yes: 'ja',
  no: 'nee',
  start: 'start',
  restart: 'herstart',
  permission: (
    <div>
      Door gebruik te maken van deze service ga je ermee akkoord dat Cerutti Film en Moniker je
      foto&apos;s mogen gebruiken als onderdeel van My Inner Wolf? en accepteer je dat je volledig
      gebonden bent aan de
      {' '}
      <a
        href="https://docs.google.com/document/d/e/2PACX-1vQsw3ftv6mu43uX0TvYzEBlzUsCLEN-BAWqnRwkkDJhQiwkXvm_Z5ap3XeaKvzR7yMGjKESFmIEYOOQ/pub"
        rel="noopener noreferrer"
        target="_blank"
      >
        Algemene voorwaarden
      </a>
      .
    </div>
  ),
  proceed: 'doorgaan',
  'assignment.uploading': <>Foto uploaden&hellip;</>,
  'error.loading':
    'Er is iets misgegaan tijdens het laden van de app. Ververs de pagina om het opnieuw te proberen.',
  'error.camera':
    'Om bij te dragen aan deze ervaring hebben we toegang tot je camera nodig. Druk op Herstart om opnieuw toestemming te vragen',
  'error.cameraAndroid': (
    <>
      <p>Geef toegang tot de camera om foto&apos;s bij te dragen aan deze video.</p>
      <p>Je kunt cameratoegang toestaan onder instellingen in je browser.</p>
    </>
  ),
  'error.notSupported': (
    <>
      <p>Helaas werkt dit project niet op je telefoon.</p>
      <p>Gebruik Safari op iOS 11 of nieuwer</p>
    </>
  ),
  'error.notSupportedAndroid': (
    <>
      <p>Helaas werkt dit project niet op je telefoon.</p>
      <p>Gebruik Chrome 53 of nieuwer</p>
    </>
  ),
  'error.inApp': (
    <>
      <p>
        Je browset momenteel binnen een andere app. Open deze website in een echte browser om mee te
        doen.
      </p>
      <p>Tik op de link om de URL te kopiëren.</p>
    </>
  ),
  'error.versionNotFound':
    'Deze versie kan helaas niet gevonden worden. Druk op Herstart om naar de frontpage te gaan.',
  loading: 'laden',
  tutorial: 'Tik op een vorm',
  'enable.sound': 'Zet geluid aan',
  'world.continue': props => (
    <>
      {'… of ga verder in jouw versie'}
      <br />
      &lsquo;
      {props.name}
      &rsquo;
    </>
  ),
  'world.build.about': 'Begin een eigen versie',
  'world.continue.about': 'Laad jouw persoonlijke versie',
  share: 'Deel',
  'share.text.default':
    'Fotografeer je innerlijke wolf, samen met alle andere bezoekers van dit webjuweeltje. Muziek, Joep Beving & Suzanne Ciani.',
  'share.text.version': props => `Luister. Doe mee in met My Inner Wolf '${props.name}'. De magic link is ${props.link}`,
  'share.overlay': 'Tik op deze link om te kopieren en te delen.',
  'share.copied': 'Link gekopieerd, klaar om te delen!',
  'share.copied.short': 'Link gekopieerd!',
  'share.bar.version': (
    <>
      Tik hier om deze versie
      <br className="responsive" />
      te delen
    </>
  ),
  mute: 'geluid is uit',
  muted: 'geluid is aan',
  'about.short': (
    <>
      <p>Ben je daar?</p>
      <p>
        Met myinnerwolf.nl kun je de donkere kant van je binnenwereld vormgeven. Je &lsquo;Inner
        wolf&rsquo;.
      </p>
      <p>
        De animatie op myinnerwolf.nl is een eindeloze collage van geüploade foto’s van alle vorige
        bezoekers samen.
      </p>
      <p className="desktop-only">
        Je kunt ook een persoonlijke versie maken met alleen je eigen beelden. Open myinnerwolf.nl
        op je telefoon en klik op de knop onderaan de colofon… Doe het, je zult er geen spijt van
        krijgen!
      </p>
      <p className="not-desktop">
        Je kunt ook een persoonlijke versie maken met alleen je eigen beelden. Klik op de knop
        onderaan dit scherm… Doe het, je zult er geen spijt van krijgen!
      </p>
      <div className="embed-container">
        <iframe
          title="trailer"
          src="https://player.vimeo.com/video/367717709"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
      <p>Waar ben je als je er even niet bent?</p>
      <p>
        Deze vraag staat centraal in een onderzoek onder jongeren met absence epilepsie in
        samenwerking met kunstenaars. Samen proberen ze taal te vinden voor de ervaring van
        afwezigheid tijdens een absence. Bekijk hierboven de onderzoeksfilm of lees meer op
        {' '}
        <a href="https://areyouthere.nl/" rel="noopener noreferrer" target="_blank">
          areyouthere.nl
        </a>
      </p>
      <p>
        Door het vormgeven van jouw eigen &lsquo;Inner Wolf&rsquo; draag je bij
        aan het groeien van een taal voor iets heel ongrijpbaars maar heel
        normaals: &lsquo;er even niet zijn&rsquo;.
      </p>
      <p>
        <a
          href="https://www.dropbox.com/sh/o1o56chnbf833ta/AABEbBESFVfTvrqSI5jZ_NFxa?dl=1"
          rel="noopener noreferrer"
          target="_blank"
        >
          Download presskit (428 MB)
        </a>
      </p>
      <p>
        Dit project is genereus ondersteund door het
        {' '}
        <a href="https://www.vsbfonds.nl/" rel="noopener noreferrer" target="_blank">
          VSBfonds
        </a>
        .
      </p>
    </>
  ),
  'about.credits': (
    <>
      <p>
        Director: Moniker
        <br />
        Assistant Director:
        <br className="responsive off-tablet" />
        <a href="http://www.nevejan.nl/" rel="noopener noreferrer" target="_blank">
          Maartje Nevejan
        </a>
      </p>
      <p>
        Concept en technologie
        <br className="responsive off-tablet" />
        door
        {' '}
        <a href="https://www.studiomoniker.com" rel="noopener noreferrer" target="_blank">
          Moniker
        </a>
        :
        <br />
        Luna Maurer &#38; Roel Wouters
        <br />
        Jolana Sýkorová
        <br />
        Thomas Boland
        <br />
        Grischa Erbe
      </p>
      <p>
        In opdracht van
        <br className="responsive off-tablet" />
        <a href="http://www.ceruttifilm.nl/" rel="noopener noreferrer" target="_blank">
          Cerutti Film
        </a>
      </p>
      <p>
        Muziek door Joep Beving & Suzanne Ciani.
        <br className="not-mobile-portrait" />
        Luister naar het nummer op
        {' '}
        <a
          href="https://open.spotify.com/track/1ApcdqaszE4EpOp50FinUy?si=txvRgCmRREeQnwELvhHzEg"
          rel="noopener noreferrer"
          target="_blank"
        >
          Spotify
        </a>
        {' '}
        of
        {' '}
        <a
          href="https://music.apple.com/nl/album/432-suzanne-ciani-rework/1436246185?i=1436246462"
          rel="noopener noreferrer"
          target="_blank"
        >
          Apple Music
        </a>
      </p>

      <p>
        Lettertypes:
        <br />
        Till van
        {' '}
        <a href="http://www.paulinelepape.com" rel="noopener noreferrer" target="_blank">
          Pauline Le Pape
        </a>
        <br />
        Vizner van Jolana Sýkorová
      </p>

      <p>
        Volg Moniker
        <br className="responsive off-tablet" />
        op
        {' '}
        <a
          href="https://www.instagram.com/studiomoniker/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Instagram
        </a>
      </p>
    </>
  ),
  again: 'again',
  'visit.mobile': 'Bezoek My Inner Wolf op je telefoon om mee te doen',
  explanation: (
    <>
      <p>
        My Inner Wolf werkt met fotos van zijn bezoekers. Als jij ook een foto wilt toevoegen hebben
        we toegang tot je camera nodig.
      </p>
    </>
  ),
  continue: 'Verder',
}

export default translations
