import keyMirror from 'keymirror'

export default keyMirror({
  SELECT_OBJECT: null,
  UNSELECT_OBJECT: null,
  CONFIRM_ASSIGNMENT: null,
  CANCEL_ASSIGNMENT: null,
  UPLOAD_REQUEST: null,
  UPLOAD_SUCCESS: null,
  UPLOAD_ERROR: null,
  GLTF_REQUEST: null,
  GLTF_PROGRESS: null,
  GLTF_LOADED: null,
  GLTF_ERROR: null,
  AUDIO_LOADED: null,
  START_APP: null,
  RESTART_APP: null,
  CAMERA_READY: null,
  SHOW_ABOUT: null,
  HIDE_ABOUT: null,
  SHOW_SHARE_OVERLAY: null,
  TOGGLE_SOUND: null,
  DEVICE_NOT_SUPPORTED: null,
  SHOW_EXPLANATION: null,
  HIDE_EXPLANATION: null,
  SCENE_DATA_LOADED: null,

  // textures
  VERSION_REQUEST: null,
  VERSION_SUCCESS: null,
  VERSION_ERROR: null,
  TEXTURES_QUEUED: null,
  TEXTURES_LOADED: null,
  TEXTURES_DESTROYED: null,
  CREATE_NEW_VERSION: null,
  ANIMATED_NEW_VERSION: null,
  VERSION_NOT_FOUND: null,

  // errors
  ERROR: null,

  // user
  SET_FACING_MODE: null,
  USER_AGREED: null,
  DECLINED_CAMERA: null,

  // copy
  SET_LANGUAGE: null,
})
