import * as Sentry from '@sentry/browser'
import * as THREE from 'three'

import store from '../store'
import {
  texturesQueued,
  texturesLoaded,
  texturesDestroyed,
  reloadTextures,
} from '../actions/version'
import ObjectMap from './ObjectMap'
import { applyBaseMaterial } from '../animation/utils/applyBaseMaterial'
import TextureLoader from './TextureLoader'
import Renderer from '../animation/Renderer'

const loader = new TextureLoader()

class TextureManager {
  MAX_ROUNDS = 5
  round = 0
  nextRoundFrame = -1
  showDebugMessages = false
  isLoading = false
  frame = -1
  loaded = []
  queue = []
  prevQueueLength = undefined
  loading = {}
  userTextures = {}

  constructor() {
    this.destroyTexture = this.destroyTexture.bind(this)
    this.addToQueue = this.addToQueue.bind(this)
    this.cancelTexture = this.cancelTexture.bind(this)
    this.hasTextureFor = this.hasTextureFor.bind(this)
  }

  init(scene, points) {
    this.reset()
    this.setScene(scene)
    this.setInOutPoints(points)

    this.anisotropy = Renderer.renderer.capabilities.getMaxAnisotropy() / 2
  }

  setInOutPoints(timeline) {
    this.timeline = timeline
  }

  setDuration(numFrames) {
    this.duration = numFrames
  }

  setNextRoundFrame(frameNumber) {
    this.nextRoundFrame = frameNumber
  }

  /**
   * Let the texture manager know about Moniker defined textures that will be used the first round
   * @param {object} staticTextures object with key of object name and texture url as value
   */
  setStaticTextures(staticTextures) {
    this.objectsWithStatic = Object.keys(staticTextures)
    Object.keys(staticTextures).forEach(name => {
      if (this.textures[name]) {
        this.textures[name].unshift(staticTextures[name])
      } else {
        this.textures[name] = [staticTextures[name]]
      }
    })
  }

  /**
   *
   * @param {object} textures object with key of object name and an array of texture urls as value
   */
  setTextures(textures) {
    this.textures = Object.keys(textures).reduce((acc, cur) => {
      const loops = Math.ceil(this.MAX_ROUNDS / textures[cur].length)
      let texs = []
      for (let i = 0; i < loops; i += 1) {
        texs = [...texs, ...textures[cur]]
      }
      acc[cur] = texs.slice(0, this.MAX_ROUNDS)
      return acc
    }, {})
  }

  /**
   * this will add textures to a "version queue"
   * after every loop we will show an older version
   * we need those older texture filenames in a queue
   * @param {object} textures object with key of object name and an array of texture urls as value
   */
  addNextTextures(textures) {
    Object.keys(textures).forEach(k => {
      if (this.textures[k]) {
        this.textures[k].push(textures[k])
      } else {
        console.warn('Can not add texture for', k, 'to queue')
      }
    })
  }

  setScene(scene) {
    this.scene = scene
  }

  /**
   * Indicate we should use the next round of textures
   */
  nextRound() {
    this.round += 1
    // loop the rounds if necessary
    if (this.round >= this.MAX_ROUNDS) {
      store.dispatch(reloadTextures())
      this.round = 0
      // remove all static textures when we finished the last round
      this.objectsWithStatic.forEach(name => {
        if (this.textures[name]) this.textures[name].shift()
      })
      this.objectsWithStatic = []
    }
    if (this.showDebugMessages) console.log('go to texture round', this.round)
  }

  /**
   * Adds object to the queue for having its texture loaded
   * @param {string} name name of object we're adding to the queue
   */
  addToQueue(name) {
    // don't add to queue if already loaded or in queue
    const check = [...this.queue, ...this.loaded]
    if (!check.includes(name) && !this.loading[name]) {
      this.queue.push(name)
    }
  }

  /**
   * Adds a user contributed texture to the list
   * @param {string} object name of the object for the texture
   * @param {string} url url of the texture image
   */
  addUserTexture(object, url) {
    this.userTextures[object] = url
    if (this.loaded[object]) {
      this.destroyTexture(object)
      this.applyTexture(object)
    } else if (this.loading) {
      this.cancelTexture(object)
      this.applyTexture(object)
    }
  }

  async applyTexture(objectName) {
    const obj = ObjectMap.getObjectByName(objectName)

    // first check for current user submitted texture
    let image = this.userTextures[objectName]
    // other wise get the texture for this round
    if (!image) {
      image = this.textures[objectName][this.round]
    }

    if (obj && image) {
      try {
        this.isLoading = true
        const texture = await this.loadTexture(objectName, image)
        const material = new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.FrontSide,
        })
        obj.material = material

        delete this.loading[objectName]
        this.loaded.push(objectName)
        this.isLoading = false
      } catch (error) {
        // if we would have cancelled the request it would not be in the loading list
        if (this.loading[objectName]) {
          delete this.loading[objectName]
          console.error('error loading texture', objectName, error)
          Sentry.captureException(error)
        }
      }
    }

    if (this.queue.length === 0 && this.showDebugMessages) {
      console.log('currently', this.loaded.length, 'textures loaded')
    }
  }

  loadTexture(objectName, image) {
    this.loading[objectName] = loader.image
    return new Promise((resolve, reject) => {
      loader.load(
        image,
        texture => {
          texture.anisotropy = this.anisotropy
          texture.flipY = false
          texture.wrapS = THREE.MirroredRepeatWrapping
          texture.wrapT = THREE.MirroredRepeatWrapping
          resolve(texture)
        },
        undefined,
        err => {
          this.isLoading = false
          reject(err)
        },
      )
    })
  }

  cancelTexture(name) {
    if (this.loading[name]) {
      this.loading[name].src = ''
      delete this.loading[name]
    }
  }

  destroyTexture(name, reset = true) {
    if (this.loaded.includes(name)) {
      const obj = ObjectMap.getObjectByName(name)
      if (obj) {
        if (this.showDebugMessages) console.log('destroying texture', name)
        // only destroy when we actually have a texture
        if (obj.material.map) {
          obj.material.map.dispose()
          if (reset) applyBaseMaterial(obj)
        }
        // obj.material.dispose()
        // obj.material = undefined
        // obj.geometry.dispose()
        // obj.geometry = undefined
        // this.scene.remove(obj)
      }

      const loadedIndex = this.loaded.indexOf(name)
      if (loadedIndex > -1) this.loaded.splice(loadedIndex, 1)
      const queueIndex = this.queue.indexOf(name)
      if (queueIndex > -1) this.queue.splice(queueIndex, 1)
    }
  }

  /**
   * Check if there's a texture available for an object
   * @param {string} objectName name of object that needs the texture
   */
  hasTextureFor(objectName) {
    return this.textures[objectName] || this.userTextures[objectName]
  }

  /**
   * Returns the load, destroy, and cancel actions to get from 1 frame to another
   * @param {number} frame Frame number you want to get the actions for
   * @param {number} since Frame number where you want to start from
   * @param {boolean} applyIntermediate Apply all the actions that happen between since and frame.
   * Useful for skipping frames.
   */
  getActions(frame, since = -1, applyIntermediate = true) {
    // find all events between two frames
    const events = Object.keys(this.timeline)
      .map(Number)
      .filter(t => t >= since && t <= frame)

    // reduce events to an array of items to load and destroy
    const actions = events.reduce(
      (acc, cur) => {
        const time = this.timeline[cur]
        if (time.in) acc.load.push(...time.in)
        if (time.out) acc.destroy.push(...time.out)
        return acc
      },
      { load: [], destroy: [] },
    )

    const toLoad = actions.load.filter(
      // don't load items that should be destroyed or are already loaded
      name => (applyIntermediate
          ? !actions.destroy.includes(name) &&
            !this.loaded.includes(name) &&
            this.hasTextureFor(name)
          : !this.loaded.includes(name) && this.hasTextureFor(name)),
    )

    // don't destroy items that should be loaded
    let toDestroy = actions.destroy.filter(
      // name => !toLoad.includes(name) && this.loaded.includes(name) && this.textures[name],
      name => !toLoad.includes(name),
    )

    const toCancel = toDestroy.filter(name => this.loading[name])

    // in case we go back in time actions.load contains all objects that are in view at that time
    // we need to destroy all objects that are not in that list
    if (since === -1) {
      toDestroy = this.loaded.filter(l => !actions.load.includes(l))
    }

    return {
      load: toLoad,
      destroy: toDestroy,
      cancel: toCancel,
    }
  }

  update(frame, shouldTick = true, force = false) {
    if (!this.textures || !this.timeline) return
    if (frame !== this.frame || force) {
      // TODO: figure out if this is necessary
      // probably only used for objects that are always in view
      if (
        this.nextRoundFrame > 0
        && this.frame < this.nextRoundFrame
        && frame >= this.nextRoundFrame
      ) {
        this.nextRound()
      }

      // in case we go back in time we need load all actions to calculate the state
      const since = frame > this.frame ? this.frame : -1
      const { load, destroy, cancel } = this.getActions(frame, since)
      if (!this.animating) load.forEach(this.addToQueue)
      destroy.forEach(this.destroyTexture)
      cancel.forEach(this.cancelTexture)

      // in case we're at the end of the loop
      // we preload the images for next 2 seconds of the next loop
      // TODO: figure out if this is the best way to preload the next loop
      // now the first 2 seconds are checked all the time
      // should not be necessary to check frames multiple times
      // FIXME: make sure we don't load the same texture twice
      const MARGIN = 60 * 2
      if (frame + MARGIN >= this.duration) {
        const diff = MARGIN - (this.duration - frame)
        const { load: nextLoad } = this.getActions(diff, -1, false)
        const filtered = nextLoad.filter(
          name => !load.includes(name) && !this.loaded.includes(name),
        )
        filtered.forEach(this.addToQueue)
      }

      this.frame = frame

      if (load.length > 0) store.dispatch(texturesQueued(load.length))
      if (destroy.length > 0) store.dispatch(texturesDestroyed(destroy.length))
    }

    if (this.queue.length === 0 && this.queue.length !== this.prevQueueLength) {
      store.dispatch(texturesLoaded(this.loaded.length))
    }
    this.prevQueueLength = this.queue.length

    if (shouldTick) this.tick()
  }

  tick() {
    if (!this.isLoading && this.queue.length) {
      this.applyTexture(this.queue.shift())
    }
  }

  reset(resetTimeline = false) {
    Object.keys(this.loading).forEach(this.cancelTexture)
    this.loaded.forEach(this.destroyTexture)
    if (resetTimeline) this.timeline = {}
    this.isLoading = false
    this.frame = -1
    this.loaded = []
    this.loading = {}
    this.queue = []
  }

  animateOut(time = 100) {
    this.animating = true
    return new Promise(resolve => {
      if (this.loaded.length === 0) {
        this.animating = false
        return resolve()
      }

      let i = 0
      Object.keys(this.loading).forEach(this.cancelTexture)
      this.loaded.forEach(loaded => {
        setTimeout(() => {
          this.destroyTexture(loaded)
          if (this.loaded.length === 0) {
            this.reset()
            this.animating = false
            return resolve()
          }
        }, i * time)
        i += 1
      })
    })
  }
}

export default new TextureManager()
