import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Assignment from '../components/Assignment/Assignment'
import {
  unselectObject, confirmAssignment, cancelAssignment, submit,
} from '../actions/app'
import { toggleFacingMode, didAgree } from '../actions/user'
import { getInstruction } from '../selectors'

const AssignmentContainer = props => <Assignment {...props} />

const mapStateToProps = state => ({
  isConfirming: state.app.isConfirming,
  isUploading: state.app.isUploading,
  facingMode: state.user.facingMode,
  instruction: getInstruction(state),
  didAgree: state.user.didAgree,
})

const mapDispatchToProps = dispatch => ({
  onClose: bindActionCreators(unselectObject, dispatch),
  onCapture: bindActionCreators(confirmAssignment, dispatch),
  onCancel: bindActionCreators(cancelAssignment, dispatch),
  onConfirm: bindActionCreators(submit, dispatch),
  toggleFacingMode: bindActionCreators(toggleFacingMode, dispatch),
  userAgreed: bindActionCreators(didAgree, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignmentContainer)
