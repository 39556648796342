import en from './english'
import nl from './dutch'

const translations = {
  en,
  nl: {
    ...en,
    ...nl,
  },
}

export default translations
