import * as THREE from 'three'

function TextureLoader(manager) {
  this.manager = manager !== undefined ? manager : THREE.DefaultLoadingManager
}

Object.assign(TextureLoader.prototype, {
  crossOrigin: 'anonymous',

  load(url, onLoad, onProgress, onError) {
    const texture = new THREE.Texture()

    const loader = new THREE.ImageLoader(this.manager)
    loader.setCrossOrigin(this.crossOrigin)
    loader.setPath(this.path)

    this.image = loader.load(
      url,
      image => {
        texture.image = image

        // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
        const isJPEG = url.search(/\.jpe?g($|\?)/i) > 0 || url.search(/^data:image\/jpeg/) === 0

        texture.format = isJPEG ? THREE.RGBFormat : THREE.RGBAFormat
        texture.needsUpdate = true

        if (onLoad !== undefined) {
          onLoad(texture)
        }
      },
      onProgress,
      onError,
    )

    return texture
  },

  setCrossOrigin(value) {
    this.crossOrigin = value
    return this
  },

  setPath(value) {
    this.path = value
    return this
  },
})

export default TextureLoader
