class ObjectMap {
  count = 0
  nested = {}

  setScene(scene) {
    this.scene = scene
    this.initialise()
  }

  initialise() {
    const map = (obj, isRoot = true) => {
      if (obj.type === 'Mesh') {
        this.count += 1
      }

      if (!isRoot) {
        this.nested[obj.name] = obj
      }

      if (obj.children) {
        obj.children.forEach(c => map(c, false))
      }
    }

    this.scene.children.forEach(c => map(c))
  }

  getObjectByName(name) {
    return this.nested[name] || (this.scene && this.scene.getObjectByName(name))
  }

  getRootObject(nameOrObj) {
    const name = typeof nameOrObj === 'string' ? nameOrObj : nameOrObj.name
    return this.findRootObjectByName(name)
  }

  getParents(nameOrObj) {
    const name = typeof nameOrObj === 'string' ? nameOrObj : nameOrObj.name
    const parents = []
    const findParent = id => {
      const nested = this.nested[id]
      if (nested && nested.parent) {
        parents.push(nested.parent)
        return findParent(nested.parent.name)
      }
      return this.getObjectByName(name)
    }
    findParent(name)

    return parents
  }

  findRootObjectByName(name) {
    const nested = this.nested[name]
    if (nested && nested.parent) {
      return this.findRootObjectByName(nested.parent.name)
    }
    return this.getObjectByName(name)
  }
}

export default new ObjectMap()
