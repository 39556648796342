import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  loadDependencies,
  // share,
  hideShare,
  hideAbout,
  unselectObject,
  hideExplanation,
  start,
} from '../actions/app'
import App from '../components/App/App'
import { getLocalisedCopy, isAppReady } from '../selectors'

const AppContainer = props => <App {...props} />

const mapStateToProps = state => ({
  selectedObject: state.app.selectedObject,
  showStartScreen: state.app.showStartScreen,
  copy: getLocalisedCopy(state),
  isCameraReady: state.app.isCameraReady,
  isAboutVisible: state.app.isAboutVisible,
  isShareVisible: state.app.isShareVisible,
  didTap: state.user.didTap,
  didDeclineCamera: state.user.didDeclineCamera,
  isDeviceSupported: state.app.isDeviceSupported,
  showExplanation: state.app.showExplanation,
  isSceneDataLoaded: state.app.isSceneDataLoaded,
  sceneData: state.app.sceneData,
  isFinishedLoading: isAppReady(state),
  versionNotFound: state.version.versionNotFound,
  isAnimatingOut: state.version.isAnimatingOut,
})

const mapDispatchToProps = dispatch => ({
  loadDependencies: bindActionCreators(loadDependencies, dispatch),
  // share: bindActionCreators(share, dispatch),
  hideShare: bindActionCreators(hideShare, dispatch),
  hideAbout: bindActionCreators(hideAbout, dispatch),
  unselectObject: bindActionCreators(unselectObject, dispatch),
  hideExplanation: bindActionCreators(hideExplanation, dispatch),
  start: bindActionCreators(start, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppContainer)
