import mitt from 'mitt'
// import device from 'current-device'
import ReactGA from 'react-ga'
import device from './current-device'

const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1

class Page {
  isIos = device.ios()
  isSafari = isSafari

  constructor() {
    Object.assign(this, mitt())

    this.updateSize()

    ReactGA.event({
      category: 'orientation',
      action: 'start',
      label: device.orientation,
    })

    window.addEventListener('resize', this.handleResize)
    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    if (isSafari) document.documentElement.classList.add('safari')
  }

  updateSize() {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.aspect = this.width / this.height

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = this.height * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  handleResize = () => {
    const { width, height, aspect } = this
    this.updateSize()
    this.emit('resize', {
      width: this.width,
      height: this.height,
      aspect: this.aspect,
      old: { width, height, aspect },
    })

    ReactGA.event({
      category: 'orientation',
      action: 'changed',
      label: `to ${device.orientation}`,
    })
  }

  handleVisibilityChange = () => {
    this.isVisible = document.visibilityState === 'visible'
    this.emit('visibilitychange', this.isVisible)
  }
}

export default new Page()
