import constants from '../actions/constants'

const initialState = {
  isLoadingVersion: true,
  isLoadingTextures: true,
  hasVersionError: false,
  numTexturesLoaded: 0,
  isAnimatingOut: false,
}

export default (state = initialState, action) => {
  if (state.hydrated === true) {
    const newState = {
      ...initialState,
      ...state,
      hydrated: false,
    }

    return newState
  }

  switch (action.type) {
    case constants.VERSION_REQUEST:
      return {
        ...state,
        isLoadingVersion: true,
        hasVersionError: false,
        isNewVersion: false,
      }

    case constants.VERSION_SUCCESS:
      return {
        ...state,
        isLoadingVersion: false,
        hasVersionError: false,
        textures: action.data.textures,
        id: action.data.id,
        name: action.data.name,
        versionNotFound: false,
      }

    case constants.VERSION_ERROR:
      return {
        ...state,
        isLoadingVersion: false,
        hasVersionError: true,
        versionNotFound: false,
      }

    case constants.VERSION_NOT_FOUND:
      return {
        ...state,
        isLoadingVersion: false,
        hasVersionError: true,
        versionNotFound: true,
        name: 'error',
      }

    case constants.TEXTURES_QUEUED:
      return {
        ...state,
        isLoadingTextures: true,
      }

    case constants.TEXTURES_LOADED:
      return {
        ...state,
        isLoadingTextures: false,
        numTexturesLoaded: action.data.totalLoaded,
      }

    case constants.TEXTURES_DESTROYED:
      return {
        ...state,
        numTexturesLoaded: state.numTexturesLoaded - action.data.num,
      }

    case constants.ANIMATED_NEW_VERSION:
      return {
        ...state,
        isAnimatingOut: false,
        isLoadingTextures: false,
      }

    case constants.CREATE_NEW_VERSION:
      return {
        ...state,
        textures: undefined,
        id: undefined,
        isNewVersion: true,
        isAnimatingOut: true,
      }

    default:
      return state
  }
}
