import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../Buttons/Button'
import Translateable from '../locale/Translatable'

const AboutTop = ({
  language, languages, setLanguage, isMuted, toggleSound, share,
}) => (
  <div className="AboutTop">
    <div className="languages">
      {languages.map(l => (
        <Button
          key={l}
          className={classNames(language === l && 'selected')}
          onTap={() => setLanguage(l)}
        >
          {l}
        </Button>
      ))}
    </div>
    <div className="mute">
      <Button onTap={toggleSound}>
        <Translateable id={isMuted ? 'mute' : 'muted'} />
      </Button>
    </div>
    <div className="mute">
      <Button onTap={share}>
        <Translateable id="share" />
      </Button>
    </div>
  </div>
)

AboutTop.propTypes = {
  language: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLanguage: PropTypes.func.isRequired,
  isMuted: PropTypes.bool.isRequired,
  toggleSound: PropTypes.func.isRequired,
  share: PropTypes.func.isRequired,
}

export default AboutTop
