import mitt from 'mitt'
import { Howl, Howler } from 'howler'

import store from './store'
import constants from './actions/constants'

class AudioPlayer {
  time = 0
  duration = 0
  isLoaded = false
  isPaused = false
  isPlaying = false

  constructor() {
    Object.assign(this, mitt())

    this.usingWebAudio = Howler.usingWebAudio

    this.sound = new Howl({
      src: [
        `${process.env.PUBLIC_URL}/sounds/bounce.webm`,
        `${process.env.PUBLIC_URL}/sounds/bounce.m4a`,
        // `${process.env.PUBLIC_URL}/sounds/ploink-min-loop-stretched.webm`,
        // `${process.env.PUBLIC_URL}/sounds/ploink-min-loop-stretched.m4a`,
      ],
      onplayerror: () => {
        this.sound.once('unlock', () => {
          this.isUnlocked = true
          this.sound.play()
        })
      },
      loop: true,
    })

    this.attachEvents()
  }

  attachEvents() {
    this.sound.once('load', () => {
      this.isLoaded = true
      this.duration = this.sound.duration()
      // TODO: do we need audio state in Redux?
      store.dispatch({ type: constants.AUDIO_LOADED })
      this.emit('load')

      console.log('audio is', this.sound.duration() * 60, 'frames long')
    })

    // this is also fired when loop ends
    this.sound.on('end', () => {
      this.isPlaying = this.sound.playing()
      this.isPaused = false
      if (this.isPlaying) {
        this.emit('loop')
      } else {
        this.emit('end')
      }
    })

    this.sound.on('play', () => {
      this.isUnlocked = true
      this.isPlaying = true
      this.isPaused = false
      this.emit('play')
    })

    this.sound.on('pause', () => {
      this.isPlaying = false
      this.isPaused = true
      this.emit('pause')
    })
  }

  setRate(rate) {
    this.sound.rate(rate)
    if (!this.sound.mute()) {
      this.sound.volume(rate)
    }
  }

  play() {
    if (!this.sound.playing()) {
      this.sound.play()
    } else {
      console.error('Sound is already playing')
    }
  }

  pause() {
    if (this.sound.playing()) {
      this.sound.pause()
    } else {
      console.error('Sound is already paused')
    }
  }

  seek(time) {
    this.seekDiff = time - this.time
    this.sound.seek(time)
    this.time = time
  }

  gotoTime(time) {
    if (time <= this.duration) {
      this.seek(time)
    }
  }

  mute(isMuted) {
    this.sound.mute(isMuted)
  }

  restart() {
    this.time = 0
    this.isPaused = false
    this.sound.rate(1)
    if (this.sound.playing()) {
      this.sound.seek(0)
    } else {
      this.sound.play()
    }
  }

  reset() {
    this.time = 0
    this.isPaused = false
    this.sound.seek(0)
    this.sound.rate(1)
  }

  tick() {
    this.time = this.sound.state() === 'loaded' && this.isUnlocked ? this.sound.seek() : 0
  }
}

export default new AudioPlayer()
