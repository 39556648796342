import instructions from '../assets/instructions'
import mapping from '../assets/mapping'

const initialState = {
  isLoadingInstructions: true,
  isLoadingMapping: true,
  instructions,
  mapping,
}

export default (state = initialState) => state
