import * as THREE from 'three'

import Page from '../utils/Page'

class SubSceneMixer {
  constructor(renderer, sceneA, sceneB) {
    this.renderer = renderer
    this.scene = new THREE.Scene()

    this.cameraOrtho = new THREE.OrthographicCamera(
      Page.width / -2,
      Page.width / 2,
      Page.height / 2,
      Page.height / -2,
      -10,
      10,
    )

    this.quadmaterial = new THREE.ShaderMaterial({
      uniforms: {
        tDiffuse1: {
          type: 't',
          value: null,
        },
        tDiffuse2: {
          type: 't',
          value: null,
        },
        mixRatio: {
          type: 'f',
          value: 0.0,
        },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = vec2( uv.x, uv.y );
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }`,
      fragmentShader: `
        uniform float mixRatio;

        uniform sampler2D tDiffuse1;
        uniform sampler2D tDiffuse2;

        varying vec2 vUv;

        void main() {

          vec4 texel1 = texture2D( tDiffuse1, vUv );
          vec4 texel2 = texture2D( tDiffuse2, vUv );

          float ratio = mix(0.0, 1.0, mixRatio);
          if (texel2.r==0.0 && texel2.g==0.0 && texel2.b==0.0) {
            ratio = mix(0.4, 1.0, mixRatio);
          }
          gl_FragColor = mix( texel2, texel1, ratio );
        }`,
    })

    const quadgeometry = new THREE.PlaneGeometry(Page.width, Page.height)

    this.quad = new THREE.Mesh(quadgeometry, this.quadmaterial)
    this.scene.add(this.quad)

    this.sceneA = sceneA
    this.sceneB = sceneB
    this.quadmaterial.uniforms.tDiffuse1.value = sceneA.fbo.texture
    this.quadmaterial.uniforms.tDiffuse2.value = sceneB.fbo.texture
  }

  render(delta, ratio) {
    this.quadmaterial.uniforms.mixRatio.value = ratio

    // Prevent render both scenes when it's not necessary
    if (ratio === 1) {
      this.sceneA.render(delta, false)
    } else {
      this.sceneA.render(delta, true)
      this.sceneB.render(delta, true)
      this.renderer.setRenderTarget(null)
      this.renderer.clear()
      this.renderer.render(this.scene, this.cameraOrtho)
    }
  }
}

export default SubSceneMixer
