import React from 'react'
import PropTypes from 'prop-types'
import Tappable from 'react-tappable/lib/Tappable'
import classNames from 'classnames'

import './Buttons.scss'

const Button = ({
  onTap, children, className, ...rest
}) => (
  <Tappable {...rest} className={classNames('Button', className)} component="button" onTap={onTap}>
    {children}
  </Tappable>
)

Button.propTypes = {
  onTap: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Button.defaultProps = {
  className: '',
}

export default Button
