import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './Viewer.scss'
import viewer from '../../viewer'

class Viewer extends Component {
  constructor(props) {
    super(props)

    this.viewer = React.createRef()
  }

  async componentDidMount() {
    const { scene } = this.props
    viewer.init({
      canvas: this.viewer.current,
      scene,
    })
  }

  render() {
    return (
      <div className="Viewer">
        <canvas ref={this.viewer} />
      </div>
    )
  }
}

Viewer.propTypes = {
  scene: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    inOutPoints: PropTypes.objectOf(PropTypes.object).isRequired,
    fov: PropTypes.shape({
      animations: PropTypes.object,
      multipliers: PropTypes.object,
    }),
  }).isRequired,
}

export default Viewer
