import React from 'react'
import PropTypes from 'prop-types'
import Tappable from 'react-tappable/lib/Tappable'
import classNames from 'classnames'

import './BottomBar.scss'
import Translateable from '../locale/Translatable'

const BottomBar = ({
  textId, icon: Icon, action, variables, background, children, className,
}) => {
  const classes = classNames(
    'BottomBar',
    className && className,
    background && 'has-background',
    !background && 'has-gradient',
    !background && 'no-bg',
    !!children && 'has-children',
    Icon && 'has-icon',
  )
  const Container = action ? Tappable : 'div'
  const props = action
    ? {
      component: 'div',
      className: classes,
      onTap: action,
    }
    : {
      className: classes,
    }

  return (
    <Container {...props}>
      {!!children && children}
      {!children && (
        <div className="text">
          <Translateable id={textId} variables={variables} />
        </div>
      )}
      {Icon && action && <Icon stopPropagation onTap={action} />}
    </Container>
  )
}

BottomBar.propTypes = {
  textId: PropTypes.string,
  icon: PropTypes.func,
  action: PropTypes.func,
  variables: PropTypes.objectOf(PropTypes.string),
  background: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}

BottomBar.defaultProps = {
  textId: null,
  icon: null,
  action: null,
  variables: null,
  background: true,
  children: null,
  className: null,
}

export default BottomBar
