import React from 'react'
import PropTypes from 'prop-types'

import Overlay from '../Overlay/Overlay'
import Translateable from '../locale/Translatable'
import LabelButton from '../Buttons/LabelButton'

const ExplanationOverlay = ({ onContinue }) => (
  <Overlay>
    <Translateable id="explanation" />
    <LabelButton label="continue" onTap={onContinue} />
  </Overlay>
)

ExplanationOverlay.propTypes = {
  onContinue: PropTypes.func.isRequired,
}

export default ExplanationOverlay
