import React from 'react'
import PropTypes from 'prop-types'

import Overlay from '../Overlay/Overlay'
import Translatable from '../locale/Translatable'
import LabelButton from '../Buttons/LabelButton'

const Error = ({
  messageId, onClose, canRestart, action,
}) => (
  <Overlay close={!!onClose} onClose={onClose}>
    <Translatable id={messageId} />
    {canRestart && (
      <LabelButton
        label="restart"
        onTap={() => {
          if (action) {
            action()
          } else {
            window.location.reload()
          }
        }}
      />
    )}
  </Overlay>
)

Error.propTypes = {
  messageId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  action: PropTypes.func,
  canRestart: PropTypes.bool,
}

Error.defaultProps = {
  onClose: null,
  canRestart: true,
  action: null,
}

export default Error
