import { Component } from 'react'
import PropTypes from 'prop-types'
import LocaleContext from './LocaleContext'

class Translateable extends Component {
  render() {
    const { id, variables } = this.props
    const { locale, catalog } = this.context
    const translated = catalog[id]
    if (!translated) console.error(`Could not find "${id}" in ${locale.toUpperCase()} catalog`)
    return typeof translated === 'function' ? translated(variables) : translated || null
  }
}

Translateable.propTypes = {
  id: PropTypes.string.isRequired,
  variables: PropTypes.objectOf(PropTypes.string),
}

Translateable.defaultProps = {
  variables: null,
}

Translateable.contextType = LocaleContext

export default Translateable
