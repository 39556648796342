import React, { Component } from 'react'
import classNames from 'classnames'

import './Hint.scss'
import PlaybackController from '../../utils/PlaybackController'
import Translateable from '../locale/Translatable'
import settings from '../../settings'

class DesktopHint extends Component {
  state = {
    shouldRender: false,
    start: settings.times.audioHint[1] + 300,
    end: settings.times.desktopHint[1],
  }

  ref = React.createRef()

  constructor(props) {
    super(props)

    this.updateFrame = this.updateFrame.bind(this)

    PlaybackController.on('frame', this.updateFrame)
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  updateFrame(frame) {
    const { shouldRender, start, end } = this.state
    if (!shouldRender && frame >= start && frame <= end) {
      if (this.mounted) this.setState({ shouldRender: true })
    } else if (shouldRender && (frame < start || frame > end)) {
      if (this.mounted) this.setState({ shouldRender: false })
    }
  }

  render() {
    const isVisible = this.state.shouldRender
    const className = classNames('DesktopHint', isVisible && 'visible')
    return (
      <div className={className}>
        <Translateable id="visit.mobile" />
      </div>
    )
  }
}

export default DesktopHint
